import React, { useState } from 'react';
import './_index.scss';

import { Link } from 'shared/interfaces/Link';
import { Image } from 'shared/interfaces/Image';
import { Button } from 'shared/interfaces/Button';

import StrapiLink from 'components/Strapi/Link';
import StrapiButton from 'components/Strapi/Button'
import StrapiImage from 'components/Strapi/Image';
import Wrapper from "components/Strapi/Wrapper";

interface CardProps {
  heading: string;
  body: string;
  date: string;
  image: Image;
  button?: Button;
  link: Link;
}

interface TriCardsProps {
  card1: CardProps;
  card2: CardProps;
  card3: CardProps;
}

const trimText = (text: string, maxLength: number): string => {
  if (!text) return "";

  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }

  return text;
}

const TriCards = ({ card1, card2, card3 }: TriCardsProps): React.ReactElement => {
  const cards = [card1, card2, card3];
  const [isButtonHovered, setIsButtonHovered] = useState<null | number>(null);

  return (
    <Wrapper>
      <div className="container container--large">
        <div className="tri-cards">
          {cards.map((card, index) => (
            <div key={index} className={`card ${isButtonHovered === index ? 'button-hovered' : ''}`}>
              {index === 2 && card?.image &&
                <div className="card__image">
                  <StrapiImage data={card.image} />
                </div>
              }
              <StrapiLink linkData={card.link} className="card__inner" programmaticOpen={true}>
                <div className="card__innerContent">
                  <div className="card__button-row">
                    {card.button && <StrapiButton
                      onMouseEnter={() => setIsButtonHovered(index)}
                      onMouseLeave={() => setIsButtonHovered(null)}
                      data={card.button}
                      customClassName="button--green"
                    />}
                    <span className="card__date">{card.date}</span>
                  </div>
                  <h4 className="card__heading">{card.heading}</h4>
                  <p className="card__body">
                    {trimText(card.body, 250)}
                  </p>
                </div>
                {index === 2 &&
                  <div className="card__arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="24" viewBox="0 0 35 24" fill="none">
                      <path d="M2.02734 10.5L0.527344 10.5L0.527344 13.5L2.02734 13.5L2.02734 10.5ZM34.088 13.0607C34.6738 12.4749 34.6738 11.5251 34.088 10.9393L24.5421 1.3934C23.9563 0.807613 23.0065 0.807613 22.4207 1.3934C21.835 1.97919 21.835 2.92893 22.4207 3.51472L30.906 12L22.4207 20.4853C21.835 21.0711 21.835 22.0208 22.4207 22.6066C23.0065 23.1924 23.9563 23.1924 24.5421 22.6066L34.088 13.0607ZM2.02734 13.5L33.0273 13.5L33.0273 10.5L2.02734 10.5L2.02734 13.5Z" fill="white" />
                    </svg>
                  </div>
                }
              </StrapiLink>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default TriCards;
