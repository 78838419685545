export const removePageId = (title: string | undefined) => {
  if (!title) return '';

  return title.replace(/^\D+\d+\.\d+((\W|\d)+)/, '');
};

export const underscoresToDashes = (title: string | undefined) => {
  if (!title) return ''
  return title.replace(/_/g, '-');
}

export const removeTags = (str: string | null) => {
  if ((str === null) || (str === '')) {
    return '';
  } else {
    str = str.toString();
  }
  return str.replace(/(<([^>]+)>)/gi, '');
}
