import React, { useState } from "react";
import ReactPaginate from 'react-paginate';

import { Button } from "components/Buttons";
import Wrapper from 'components/Strapi/Wrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';

import "./stylesheets/scholarships.scss";

interface ScholarshipsProps {
  data: any;
  recordsPerPage?: string;
  bgColor?: {
    selectedColor: string;
  }
}

const DetailListItem = (props: any) => {
  return <li className='list-item list-item--details'>
    <span className='list-item__key'>{props.itemKey}</span>
    <span className='list-item__value'>{props.itemValue}</span>
  </li>;
}

export const ScholarshipCard = (props: any): React.ReactElement => {
  return (
    <div className='scholarships__card'>
      <div className='branding'></div>
      <div className='info'>
        <h3 className='info__name'>
          <a href='https://www.grantinterface.com/Home/Logon?urlkey=tcfrichmondscholarship' target='_blank' rel='noreferrer'>{props.scholarship_name}</a>
        </h3>
        <ul className='info__details'>
          <DetailListItem itemKey='Student Type' itemValue={props.scholarship_student_type} />
          <DetailListItem itemKey='Resides In' itemValue={props.scholarship_student_resides} />
          <DetailListItem itemKey='GPA' itemValue={props.scholarship_gpa} />
          <DetailListItem itemKey='School Type' itemValue={props.scholarship_hs_type} />
          <DetailListItem itemKey='Ethnicity' itemValue={props.scholarship_race_ethnicity} />
          <DetailListItem itemKey='Renew or Reapply or N/A' itemValue={props.scholarship_renew_reapply} />
          <DetailListItem itemKey='Financial Forms' itemValue={props.scholarship_financial_form_reqd} />
          <DetailListItem itemKey='Major/Career' itemValue={props.scholarship_major_career} />
          <DetailListItem itemKey='College' itemValue={props.scholarship_college_type} />
          <DetailListItem itemKey='Requirements' itemValue={props.scholarship_affiliation} />
        </ul>
      </div>
      <div className='actions'>
              <div className='actions__amount'>{props.scholarship_award_amount}</div>
        <div className='actions__awards'>{props.scholarship_number_of_awards}{!isNaN(props.scholarship_number_of_awards) && ' AWARD(S)'}</div>
          <div className='actions__cta'>
            <Button text='APPLY NOW' openInNewTab={true} link='https://www.grantinterface.com/Home/Logon?urlkey=tcfrichmondscholarship' />
            </div>
        <div className='actions__more-details'>
              <a href={props.additional_details_url} target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faExternalLink} />MORE DETAILS</a>
          </div>
        <div className='actions__id'>ID: {props.scholarship_ssid}</div> 
          </div>
    </div>  
  )
};

export const Scholarships = ({ data, recordsPerPage, bgColor }: ScholarshipsProps) => {
  let scholarshipIndex = 0;
  const itemsPerPage = Number(recordsPerPage) || 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
  };

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className='scholarships'>
        {currentItems && currentItems[0] && currentItems?.map((scholarship: any) => (
          <ScholarshipCard key={scholarshipIndex+=1} {...scholarship} />
        ))}
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
        />
      </div>
    </Wrapper>
  );
};
