import React, { useState } from "react";
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { Button } from "components/Buttons";
import { Image as IImage } from "shared/interfaces/Image";
import { Button as IButton } from "shared/interfaces/Button";
import StrapiButton from "components/Strapi/Button";
import Wrapper from "components/Strapi/Wrapper";

import 'pure-react-carousel/dist/react-carousel.es.css';
import "./stylesheets/featured-cards.scss";

type FeaturedCardsProps = {
  id: number;
  image: IImage;
  storyHeader: string;
  date: string;
  storyType: string;
  content: string;
  author: string;
  cta: IButton;
}

type FeaturedCarouselProps = {
  cards: FeaturedCardsProps[];
  bgColor?: {
    selectedColor: string
  };
}

interface CustomDotGroupProps {
  setCurrentIndex: (index: number) => void;
  totalSlides: number;
  currentIndex: number;
}

const CustomDotGroup = ({ setCurrentIndex, totalSlides, currentIndex }: CustomDotGroupProps): React.ReactElement => {
  return (
    <div className="news-card__custom-dots">
      {Array.from({ length: totalSlides }).map((_, i) => {
        const isActive = i === currentIndex;
        return (
          <button
            key={i}
            onClick={() => setCurrentIndex(i)}
            className={`news-card__custom-dot ${isActive ? 'news-card__custom-dot--selected' : ''}`}
          >
          </button>
        );
      })}
    </div>
  );
};

const trimText = (text: string, maxLength: number): string => {
  if (!text) return "";

  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }

  return text;
}

export const FeaturedCards = (props: FeaturedCarouselProps): React.ReactElement => {
  const { cards, bgColor } = props;
  const [selectedTab, setSelectedTab] = useState(0);

  const nextSlide = () => {
    if (selectedTab < cards.length - 1) {
      setSelectedTab(selectedTab + 1);
    } else {
      setSelectedTab(0); // Loop back to the first slide
    }
  };

  const previousSlide = () => {
    if (selectedTab > 0) {
      setSelectedTab(selectedTab - 1);
    } else {
      setSelectedTab(cards.length - 1) // Loop to the last slide
    }
  }

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className="featured-card container container--large">
        <div className="featured-card__mobile">
          <CarouselProvider
            naturalSlideWidth={3}
            naturalSlideHeight={3}
            totalSlides={cards.length}
            currentSlide={selectedTab}
            isIntrinsicHeight={true}
            dragEnabled={false}
            visibleSlides={1}
            className="featured-card__mobile__carousel"
          >
            <Slider>
              {cards?.map(({ id, image, storyHeader, date, storyType, content, author, cta }) => {
                const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
                const formattedDate = new Date(date).toLocaleDateString('en-us', options);

                return (
                  <Slide key={id} index={id}>
                    <div className="featured-card__mobile__carousel__container">
                      <div className="featured-card__mobile__carousel__container__content__image"
                        style={{
                          backgroundImage: `linear-gradient(359deg, #000 13.71%, rgba(0, 0, 0, 0.00) 60.34%), 
                      url('${image.image.data.attributes.url}')`
                        }} >
                        <h2>{storyHeader}</h2>
                      </div>
                      <div className="featured-card__mobile__carousel__container__content__text background--green">
                        <h3>{formattedDate}</h3>
                        <h5>{storyType}</h5>
                        <p>{trimText(content, 140)}</p>
                        <h5>By {author}</h5>
                        <StrapiButton data={cta} customClassName={`button--${cta.className}`} />
                      </div>
                    </div>
                  </Slide>
                )
              })}
            </Slider>
          </CarouselProvider>
        </div>
        <div className="featured-card__desktop">
          {cards.length > 1 &&
            <div className="featured-card__desktop__arrow--left">
              <Button
                onClick={() => previousSlide()}
                customClassName="button--news-card-arrow"
                icon={"arrow-left"}
              />
            </div>
          }

          <div className="featured-card__desktop__carousel-container">
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={125}
              totalSlides={cards.length}
              currentSlide={selectedTab}
              dragEnabled={false}
              isIntrinsicHeight={true}
              visibleSlides={1}
              className="featured-card__desktop__carousel"
            >
              <Slider>
                {cards.map(({ id, image, storyHeader, date, storyType, content, author, cta }) => {
                  const formattedDate = new Date(date).toLocaleDateString('en-us', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  });

                  return (
                    <Slide key={id} index={id}>
                      <div className="slide-content">
                        <div className="slide-content__image"
                          style={{ backgroundImage: `linear-gradient(359deg, #000 13.71%, rgba(0, 0, 0, 0.00) 60.34%), url('${image.image.data.attributes.url}')` }}
                        >
                          <h2>{storyHeader}</h2>
                        </div>
                        <div className="slide-content__text background--green">
                          <h3>{formattedDate}</h3>
                          <h5>{storyType}</h5>
                          <p>{trimText(content, 140)}</p>
                          <h5>By {author}</h5>
                          <div className="slide-content__text__button">
                            <StrapiButton data={cta} customClassName="button--featured-card-white" />
                          </div>
                        </div>
                      </div>
                    </Slide>
                  );
                })}
              </Slider>
            </CarouselProvider>
          </div>

          {cards.length > 1 &&
            <div className="featured-card__desktop__arrow">
              <Button
                onClick={() => nextSlide()}
                customClassName="button--news-card-arrow"
                icon={"arrow-right"}
              />
            </div>
          }
        </div>
        {cards.length > 1 &&
          <CustomDotGroup
            setCurrentIndex={setSelectedTab}
            totalSlides={cards.length}
            currentIndex={selectedTab}
          />
        }
      </div>
    </Wrapper>
  )
};