import { Nav } from './lib/Nav';
import './stylesheets/header.scss';

//type HeaderProps = {
  //title?: string;
  //subHead?: string;
  //buttonText?: string;
  //bgImg?: string;
//}

export const Head = () => {
  return (
    <header>
      <Nav />
    </header>
  );
}

export default Head;
