import { ReactNode } from 'react';
import parse from 'html-react-parser';

interface NavItemProps {
  children?: ReactNode | string;
  contents?: ReactNode | string;
  link?: any;
  onBlur?: any;
  onHover?: any;
  subMenuItems?: any;
  color?: string;
  itemName?: string;
  rel?: boolean;
}

export const NavItem = ({ children, contents, link = '/', onBlur, onHover, subMenuItems, rel }: NavItemProps) => {

  return (
    <li
      onMouseOver={() => {
        if (onHover) {
          onHover(subMenuItems);
        }
      }}
      onMouseOut={() => {
        () => onBlur;
      }}
    >

      {!rel ?
        <a href={link}>
          <span>{typeof (contents) === 'string' ? parse(contents) : contents}</span>
        </a>
        :
        <a href={link} target='_blank' rel="noreferrer">
          <span>{typeof (contents) === 'string' ? parse(contents) : contents}</span>
        </a>
      }
      {false && <div>
        <ul>
          {children}
        </ul>
      </div>}
    </li>
  );
}
