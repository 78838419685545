import React from "react";
import { Color } from "shared/interfaces/Color";
import Text from "components/Text";
import './stylesheets/copy-blocks.scss';
import { mapColor } from "shared/helpers/colorMapping";
import img1 from 'shared/assets/images/radial-white.png'

type SixtyFortyProps = {
    content1: string,
    content2: string,
    bgColor: Color
}

export const SixtyFortyFundType = (props: SixtyFortyProps): React.ReactElement => {
    return (
        <div className="container container--large">
        <div className="sixty-forty">
            <div className={`sixty-forty__spacer background--${props.bgColor ? mapColor(props.bgColor.selectedColor) : ''}`}></div>
            <div className="sixty-forty__content1"><Text text={props.content1}/></div>
            <div className={`sixty-forty__content2 background--${props.bgColor ? mapColor(props.bgColor.selectedColor) : ''}`} style={{ backgroundImage: img1 }}><Text text={props.content2}/></div>
        </div>
        </div>
    )
}