import * as React from "react";
import * as ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { App } from "./App";
import { StrapiProvider } from 'shared/context/strapi-graphql';
import { AnalyticsProvider } from "shared/context/google-analytics";

/* I18n */
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import en from 'translations/en/en.json';

const container = document.getElementById("root");
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  react: {
    useSuspense: false,
  },
  returnObjects: true,
  resources: {
    en: {
      translation: en
    }
  },
});

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <StrapiProvider>
        <AnalyticsProvider>
          <App />
        </AnalyticsProvider>
      </StrapiProvider>
    </I18nextProvider>
  </React.StrictMode>,
);

serviceWorker.unregister();
reportWebVitals();

