import React, { useState } from "react";

import { Button } from "components/Buttons";
import { CarouselProvider, DotGroup, Slide, Slider } from "pure-react-carousel";
import { IntroH1, IntroProps } from "components/CopyBlocks/Intro";

import StrapiImage from 'components/Strapi/Image';
import Wrapper from "components/Strapi/Wrapper";

import { Image as ImageProps } from 'shared/interfaces/Image';

import "./stylesheets/h1-image-carousel.scss";
import 'pure-react-carousel/dist/react-carousel.es.css';

export type H1ImageCarouselProps = {
  introH1: IntroProps;
  bgColor?: {
    selectedColor: string
  },
  tabs: {
    image: ImageProps;
    title: string;
  }[]
};

export const H1ImageCarousel = (props: H1ImageCarouselProps): React.ReactElement => {
  const { tabs, introH1, bgColor } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentTitle, setCurrentTitle] = useState(tabs[0].title); // Initialize with the title of the first tab

  const updateTitle = (newIndex: number) => {
    setCurrentTitle(tabs[newIndex].title);
  };

  const nextSlide = () => {
    const newIndex = selectedTab < tabs.length - 1 ? selectedTab + 1 : 0;
    setSelectedTab(newIndex);
    updateTitle(newIndex);
  };

  const previousSlide = () => {
    const newIndex = selectedTab > 0 ? selectedTab - 1 : tabs.length - 1;
    setSelectedTab(newIndex);
    updateTitle(newIndex);
  };

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className="h1-image container container--large">
        <div className="h1-image__description">
          <IntroH1
            title={introH1?.title}
            desc={introH1?.desc}
            variation='column'
            cta={introH1.cta}
          />
        </div>
        <CarouselProvider
          naturalSlideWidth={3}
          naturalSlideHeight={3}
          totalSlides={tabs.length}
          currentSlide={selectedTab}
          isIntrinsicHeight={true}
          dragEnabled={false}
          visibleSlides={1}
          className="h1-image__carousel"
        >
          <div className="h1-image__carousel__navigation">
            {tabs?.length > 1 &&
              <div className="h1-image__carousel__btn-left">
                <Button
                  onClick={() => previousSlide()}
                  customClassName="button--h1-image-carousel-arrow"
                  icon={"arrow-left"}
                />
              </div>
            }
            <h5>{currentTitle}</h5>
            {tabs?.length > 1 &&
              <div className="h1-image__carousel__btn-right">
                <Button
                  onClick={() => nextSlide()}
                  customClassName="button--h1-image-carousel-arrow"
                  icon={"arrow-right"}
                />
              </div>
            }
          </div>
          <Slider className='h1-image__carousel__slider-container'>
            {tabs?.map(({ image }, index) => {

              return (
                <Slide index={index} key={index}>
                  <div className="h1-image__carousel__content-container">
                    <StrapiImage data={image} />
                  </div>
                </Slide>
              )
            })}
          </Slider>
          {tabs?.length > 1 &&
            <DotGroup
              className='h1-image__carousel__dot'
            />
          }
        </CarouselProvider>
      </div>
    </Wrapper>
  );
}
