import React, { useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { useStrapi, CollectionConfig } from "shared/context/strapi-graphql";
import { collectionQueryMap, CollectionName } from "shared/context/collectionQueryMap";
import { SearchInput, DropDown } from '../Inputs';

import StrapiImage from 'components/Strapi/Image';
import StrapiLink from 'components/Strapi/Link';

import placeholderImg from 'shared/assets/images/filtered-list-placeholder.png'
import videoPlaceholderImg from 'shared/assets/images/filtered-list-video-placeholder.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './_index.scss';


interface FilteredListProps {
  collections: CollectionName[];
  itemsPerPage?: number;
  frequentlySearchedTerms?: { term: string }[];
  displayDropDown?: boolean;
}

export type Page = {
  direction?: string,
  type?: string
}

type CollectionsType = { [key: string]: CollectionConfig };

interface IDisplayResults {
  isMobile?: boolean, totalCount: number | null, currentPage: number, itemsPerPage: number
}

type IHandlePageChange = (
  page: number | Page,
  setCurrentPage: Dispatch<SetStateAction<number>>,
  setIsPaginationUpdate?: Dispatch<SetStateAction<boolean>>
) => void

export function debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null = null;

  return function (...args: Parameters<T>): void {
    const later = () => {
      timeout = null;
      func(...args);
    };

    if (timeout !== null) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);
  };
}

export const SkeletonLoader = ({ count }: { count: number }) => {
  return (
    <div className="filtered-list__skeleton-loader">
      {Array.from({ length: count }).map((_, index) => (
        <div key={index} className="filtered-list__skeleton-item">
          <div className="filtered-list__skeleton-content"></div>
        </div>
      ))}
    </div>
  );
};

export const generatePageNumbers = (currentPage: number, totalPages: number) => {
  const pages: (number | Page)[] = [];
  const maxPagesToShow = 5;
  let startPage: number;

  if (totalPages <= maxPagesToShow) {
    startPage = 1;
  } else if (currentPage <= maxPagesToShow - 2) {
    startPage = 1;
  } else if (currentPage + 2 >= totalPages) {
    startPage = totalPages - (maxPagesToShow - 1);
  } else {
    startPage = currentPage - 2;
  }

  for (let i = startPage; i < startPage + maxPagesToShow; i++) {
    pages.push(i);
    if (i === totalPages) break; // Stop if we reach total pages
  }

  if (startPage > 1) {
    pages.unshift({ type: 'ellipsis', direction: 'left' }); // Left ellipsis
    pages.unshift(1); // Add the first page
  }

  if (typeof pages[pages.length - 1] === 'number' && pages[pages.length - 1] as number < totalPages) {
    pages.push({ type: 'ellipsis', direction: 'right' });
    pages.push(totalPages);
  }

  return pages;
};

export const displayResults = ({ isMobile, totalCount, currentPage, itemsPerPage }: IDisplayResults) => {

  if (!totalCount) {
    return;
    // return (
    //   <p className={`filtered-list__results${isMobile ? 'Mobile' : 'Desktop'}`}>
    //     Displaying 0 of 0 results
    //   </p>
    // )
  }

  // Calculate start and end indices
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalCount); // Ensure it does not exceed totalCount

  return (
    <p className={`filtered-list__results${isMobile ? 'Mobile' : 'Desktop'}`}>
      Displaying {endIndex} of {totalCount} results
    </p>
  );
};

export const handlePageChange: IHandlePageChange = (page, setCurrentPage, setIsPaginationUpdate) => {
  if (typeof page === 'object' && page.type === 'ellipsis') {
    // Handle ellipsis click if needed
  } else if (typeof page === 'number') {
    // Update current page and URL query parameter
    setCurrentPage(page);
    // setSearchParams({ page: page.toString() });
    setIsPaginationUpdate && setIsPaginationUpdate(true);
  }
};

export const FilteredList = ({
  collections,
  itemsPerPage = 10,
  frequentlySearchedTerms,
  displayDropDown
}: FilteredListProps): React.ReactElement | null => {
  const defaultCollections = collectionQueryMap(collections)
  const defaultFrequentlySearched = frequentlySearchedTerms ? frequentlySearchedTerms.map(termObj => termObj.term) : [];
  const debounceTime = 500; //ms

  const [filters, setFilters] = useState<Record<string, string | string[]>>({ keywords: '', frequentSearches: [], collections: '' });
  const [pageData, setPageData] = useState<any[] | null>(null);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCollections, setSelectedCollections] = useState<CollectionsType>(defaultCollections);
  const [collectionCounts, setCollectionCounts] = useState<Record<string, number>>({});
  const [isPaginationUpdate, setIsPaginationUpdate] = useState(false);
  const [totalPages, setTotalPages] = useState(0)

  const calculateOffset = (page: number) => (page - 1) * itemsPerPage;

  const { pageData: strapiPageData, collectionCounts: strapiCollectionCounts, fetchData } = useStrapi({
    collections: selectedCollections,
    dynamicPagination: { offset: calculateOffset(currentPage), itemsPerPage },
    filters: filters,
    setIsLoading: setIsLoading,
    passedCounts: collectionCounts,
    isPaginationUpdate
  });

  const updateFilters = useCallback(debounce((newFilters) => {
    setFilters(newFilters);
  }, debounceTime), []);

  useEffect(() => {
    fetchData();
  }, [fetchData, currentPage, filters, selectedCollections]);

  // Load current page from session storage on initial render
  useEffect(() => {
    const storedPage = sessionStorage.getItem('currentPage');
    if (storedPage) {
      setCurrentPage(parseInt(storedPage, 10));
    }
  }, []);

  // Save current page to session storage whenever it changes
  useEffect(() => {
    sessionStorage.setItem('currentPage', currentPage.toString());
  }, [currentPage]);

  useEffect(() => {
    if (!isLoading) {
      setPageData(strapiPageData);
      setCollectionCounts(strapiCollectionCounts);
      setTotalCount(Object.values(strapiCollectionCounts).reduce((sum, count) => sum + count, 0));
    }
  }, [strapiPageData, collectionCounts, isLoading]);

  useEffect(() => {
    if (currentPage !== 1) {
      setIsPaginationUpdate(true);
      fetchData();
    }
  }, [currentPage]);

  useEffect(() => {
    // Uupdate totalPages based on the new totalCount
    const newTotalPages = totalCount ? Math.ceil(totalCount / itemsPerPage) : 0;
    setTotalPages(newTotalPages);
  }, [filters, totalCount, currentPage, itemsPerPage]);

  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFilters = { ...filters, keywords: event.target.value };
    setCurrentPage(1);
    updateFilters(newFilters);
  };

  const handleCollectionChange = (selectedOptions: string[]) => {
    setCurrentPage(1);

    const collectionNames = selectedOptions as CollectionName[];
    setSelectedCollections(
      collectionNames.length > 0 ? collectionQueryMap(collectionNames) : defaultCollections
    );

    setFilters({ ...filters, collections: selectedOptions.join(',') }); // Update filters to trigger re-fetch
  };

  const handleFrequentSearchChange = (selectedOptions: string[]) => {
    setCurrentPage(1);
    setFilters({ ...filters, frequentSearches: selectedOptions });
  };

  return (
    <div className="filtered-list" style={{ minHeight: (itemsPerPage * 100) + 'px' }}>
      <div className='filtered-list--inputs'>
        <div className='container container--large'>
          <div>
            <SearchInput
              name='keyword'
              placeholder='Search'
              label='Keyword'
              type='input--keyword-search'
              onChange={handleKeywordChange}
            />
            {displayResults({ isMobile: true, totalCount, currentPage, itemsPerPage })}
          </div>

          {frequentlySearchedTerms && frequentlySearchedTerms[0] && (
            <DropDown
              dropDownName='Frequent Searches'
              options={defaultFrequentlySearched}
              onSelectionChange={handleFrequentSearchChange}
            />
          )}

          {/*displayDropDown Boolean Conditionally Renders Out Collections Drop Down*/}
          {displayDropDown && collections && (
            <DropDown
              dropDownName='Collections'
              options={collections}
              onSelectionChange={handleCollectionChange}
            />
          )}
          {displayResults({ isMobile: false, totalCount, currentPage, itemsPerPage })}
        </div>
      </div>

      {isLoading || !pageData ? (
        <SkeletonLoader count={itemsPerPage} />
      ) : pageData && pageData.length > 0 ? pageData.map((item, index) => (
        <div key={index} className="filtered-list__item">
          <StrapiLink
            linkData={!item?.attributes?.link ? { href: item?.attributes?.slug } : item.attributes.link}
            className="container container--large"
            programmaticOpen={true}
          >
            <div className="filtered-list__itemImgWrapper">
              {item?.attributes?.previewImg ?
                <StrapiImage
                  data={item.attributes.previewImg}
                /> :
                <img src={item.attributes.type === 'video' ? videoPlaceholderImg : placeholderImg} alt={item.attributes.title} />
              }
            </div>
            <div className="filtered-list__itemContent">
              <h3 className="filtered-list__itemContentTitle">{item.attributes.title}
                <div>{item.attributes.type ? '|' : ''}</div>
                <span className="filtered-list__itemContentType">{item.attributes.type}</span>
              </h3>
              <p>{item.attributes.description}</p>
            </div>
          </StrapiLink>
        </div>
      )) : (
        <div className="filtered-list__noResults">
          <div className="container container--large">
            <h3>No results found</h3>
            <p>Try adjusting your search filters</p>
          </div>
        </div>
      )}
      {pageData && pageData.length > 0 && <>
        <div className='filtered-list__pagination'>
          <div className='container container--large'>
            <button
              className='filtered-list__paginationArrowLeft'
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1, setCurrentPage, setIsPaginationUpdate)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            {generatePageNumbers(currentPage, totalPages).map((page, index) => {
              let displayText;
              let buttonClass = 'filtered-list__paginationBtn';

              if (typeof page === 'object' && page.type === 'ellipsis') {
                displayText = '...';
                buttonClass += ' filtered-list__paginationEllipsis';
              } else {
                displayText = page.toString();
                buttonClass += currentPage === page ? ' active' : '';
              }

              return (
                <button
                  key={index}
                  className={buttonClass}
                  onClick={() => handlePageChange(page, setCurrentPage, setIsPaginationUpdate)}
                >
                  {displayText}
                </button>
              );
            })}
            <button
              className='filtered-list__paginationArrowRight'
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1, setCurrentPage, setIsPaginationUpdate)}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
      </>
      }
    </div>
  );
};