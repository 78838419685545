import image404 from 'shared/assets/images/error-404.png';
import './stylesheets/error-page.scss';

export type NotFoundProps = {
  message: string;
  code: string;
};

export const NotFound = (props: NotFoundProps) => {
  const { message } = props;

  return (
    <div className='error-page'>
      <div className='error-page__message'>
        <img src={image404} />
        <h2>{message}</h2>
        <p>Page Not Found</p>
      </div>
    </div>
  );
};
