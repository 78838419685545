import { ReactNode } from 'react';

type NavRowType = {
  style?: string;
  children: ReactNode;
  onBlur?: any;
}

export const NavRow = ({ children, onBlur, style }: NavRowType) => {
  return (
    <ul onMouseLeave={onBlur} className={style}>
      {children}
    </ul>
  );
}
