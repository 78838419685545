import React, { useState } from 'react';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';

import { Button } from 'components/Buttons';
import StrapiButton from 'components/Strapi/Button';
import StrapiImage from 'components/Strapi/Image';

import { Button as ButtonProps } from 'shared/interfaces/Button';
import { Image as ImageProps } from 'shared/interfaces/Image';
import Text from 'components/Text';
import Wrapper from "components/Strapi/Wrapper";

import './_index.scss';

interface SlideProps {
  image: ImageProps;
  title: string;
  description: string;
  buttonText: string;
  cta?: ButtonProps;
}

interface FocusCarouselProps {
  slides: SlideProps[];
  title: string;
  description: string;
  bgColor?: {
    selectedColor: string
  };
}

interface CustomDotGroupProps {
  setCurrentIndex: (index: number) => void;
  totalSlides: number;
  currentIndex: number;  // Add this prop to know which slide is currently active
}

const CustomDotGroup = ({ setCurrentIndex, totalSlides, currentIndex }: CustomDotGroupProps): React.ReactElement => {
  return (
    <div className="focus-carousel__custom-dots">
      {Array.from({ length: totalSlides }).map((_, i) => {
        const indexToSet = i
        const isActive = indexToSet === currentIndex;  // Check if the dot corresponds to the current index
        return (
          <button
            key={i}
            onClick={() => setCurrentIndex(indexToSet)}
            className={`focus-carousel__custom-dot ${isActive ? 'focus-carousel__custom-dot--selected' : ''}`}
          >
          </button>
        );
      })}
    </div>
  );
};

export const FocusCarousel = ({ slides, title, description, bgColor }: FocusCarouselProps): React.ReactElement => {

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const handleBack = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const slideClass = (index: number) => {
    return index === currentIndex ? 'focus-carousel__highlighted' : 'focus-carousel__normal';
  };

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className="focus-carousel container container--large">
        <div className="focus-carousel__header">
          <div className="focus-carousel__header--inner">
            <h3 className="focus-carousel__title">{title}</h3>
            <Text text={description} className='focus-carousel__description' />
          </div>
          <div className="focus-carousel__header--empty" />
        </div>
        <div className="focus-carousel__desktop">
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={slides.length}
            visibleSlides={4}
            dragEnabled={false}
          >
            <Slider className="focus-carousel__slider">
              {slides.map((slide, index) => (
                <div key={index} className={slideClass(index)}>
                  <Slide index={index} innerClassName="focus-carousel__slide">
                    {slide && (
                      <>
                        <div className="focus-carousel__imgWrapper">
                          {slide.image && <StrapiImage data={slide.image} />}
                        </div>
                        <div className="focus-carousel__content">
                          <h4 className="focus-carousel__heading">{slide.title}</h4>
                          {currentIndex === index && (
                            <div className="focus-carousel__descriptionWrapper">
                              <Text className="focus-carousel__description" text={slide.description} />
                            </div>
                          )}
                          {slide.cta && (
                            <StrapiButton data={slide.cta} customClassName="button--white focus-carousel__button--centered" />
                          )}
                        </div>
                        {index === currentIndex && (
                          <div className="focus-carousel__controls">
                            <div className="focus-carousel__controlsWrapper">
                              <Button onClick={() => handleBack()} customClassName="button--arrow focus-carousel__button--back">
                                <FontAwesomeIcon icon={faArrowLeft} />
                              </Button>
                              {slide.cta && (
                                <StrapiButton data={slide.cta} customClassName="button--white focus-carousel__button--centered" />
                              )}
                              <Button onClick={() => handleNext()} customClassName="button--arrow focus-carousel__button--next">
                                <FontAwesomeIcon icon={faArrowRight} />
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </Slide>
                </div>
              ))}
            </Slider>
            <CustomDotGroup setCurrentIndex={setCurrentIndex} totalSlides={slides.length} currentIndex={currentIndex} />
          </CarouselProvider>
        </div>
        <div className="focus-carousel__mobile">
          <CarouselProvider
            naturalSlideWidth={450}
            naturalSlideHeight={635} // Adjust based on your content
            totalSlides={slides.length}
            visibleSlides={1}
            dragEnabled={isMobile}
            infinite={true}
          >
            <Slider className="focus-carousel__slider">
              {slides.map((slide, index) => (
                <Slide index={index} key={index}>
                  <div className="focus-carousel__slideWrapper">
                    <div className="focus-carousel__imgWrapper">
                      {slide.image && (
                        <StrapiImage data={slide.image} />
                      )}
                    </div>
                    <div className="focus-carousel__content">
                      <h4 className="focus-carousel__heading">{slide.title}</h4>
                      <div className="focus-carousel__descriptionWrapper">
                        <Text className="focus-carousel__description" text={slide.description} />
                      </div>
                      <div className="focus-carousel__buttonContainer">
                        {slide.cta && (
                          <StrapiButton data={slide.cta} customClassName='button--white focus-carousel__button--centered' />
                        )}
                      </div>
                    </div>
                  </div>
                </Slide>
              ))}
            </Slider>
            <DotGroup className="focus-carousel__dots" />
          </CarouselProvider>
        </div>
      </div>
    </Wrapper>
  );
};

export default FocusCarousel;
