import React from 'react';
import Wrapper from "components/Strapi/Wrapper";
import Text from 'components/Text';
import { mapColor } from "shared/helpers/colorMapping";

import './stylesheets/infographic-standard.scss';

export type InfographicFactsProps = {
  metricType: string;
  metricTitle: string;
  className?: string; //deprecated
  bgColor?: {
    selectedColor: string;
  }
  variation?: string;
  fontColor?: 'navy' | 'white'
}

export type InfographicStandardProps = {
  isCarousel?: boolean;
  metricHeader?: string;
  metricParagraph?: string;
  metricFacts: InfographicFactsProps[];
}

export const InfographicStandard = (props: InfographicStandardProps): React.ReactElement => {
  const { isCarousel, metricHeader, metricParagraph, metricFacts } = props;

  return (
    <Wrapper>
      <div className="container container-large">
        <div className={!isCarousel ? "infographic-standard" : "infographic-standard infographic-standard--carousel"}>
          {!isCarousel ? (
            <div className='infographic-standard__overview'>
              <h1>{metricHeader}</h1>
              <Text text={metricParagraph} />
            </div>
          ) : (
            <div className='infographic-standard__overview--carousel'>
              <h1>{metricHeader}</h1>
            </div>
          )}
          <div className='infographic-standard__bar-container'>
            {metricFacts && metricFacts.map(({ metricType, metricTitle, variation, bgColor, fontColor }, index) => {
              return (
                <div key={index} className={`infographic-standard__${variation} background--${mapColor(bgColor?.selectedColor || '')} fontColor--${fontColor}`}>
                  <div className='infographic-standard__content'>
                    <h2>{metricType}</h2>
                    <Text text={metricTitle} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}