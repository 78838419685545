import React from 'react';

import './stylesheets/dividers.scss';

interface ColorDividerProps {
  className?: string;
  size?: 'sm' | 'lg'; // Added size prop
}

export const ColorDivider = (props: ColorDividerProps): React.ReactElement => {
  const { className, size } = props;
  const sizeClass = size === 'lg' ? 'color-groups--lg' : 'color-groups--sm';

  return (
    <>
        <section className={`color-groups ${sizeClass} ${className ? className : ''}`}>
          {size === 'lg' ?
            <div className='color-group'>
              <span className='color-group--lg-1'></span>
              <span className='color-group--lg-2'></span>
              <span className='color-group--lg-3'></span>
              <span className='color-group--lg-4'></span>
            </div>
            : <>
              <div className='color-group color-group--brand-blue'>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className='color-group color-group--teal'>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className='color-group color-group--forest-green'>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className='color-group color-group--pomegranate-red'>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </>}
        </section>
    </>
  )
};
