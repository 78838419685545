import React, { useEffect, useRef, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import './_index.scss';

interface PopupModalProps {
  children?: ReactNode;
  onRef?: (ref: { openDialog: () => void, closeDialog: () => void }) => void;
}

const PopupModal = ({ children, onRef }: PopupModalProps): React.ReactElement => {
	const dialogRef = useRef<HTMLDialogElement>(null);

	const openDialog = (): void => {
		if (dialogRef.current) {
			dialogRef.current.showModal();
		}
	}

	const closeDialog = (): void => {
		if (dialogRef.current) {
			dialogRef.current.close();
		}
	}

	const handleBackdropClick = (event: React.MouseEvent<HTMLDialogElement | HTMLDivElement>): void => {
    if (event.target === event.currentTarget) {
      closeDialog();
    }
  }

	// Expose the openDialog function to the parent (or any component that uses this PopupModal)
  useEffect(() => {
    if (onRef) {
      onRef({ openDialog, closeDialog });
    }
  }, [onRef]);

	return (
		<dialog className="popupModal" ref={dialogRef} onClick={handleBackdropClick}>
			<div className='popupModal__content'>
				<button className="popupModal__close" onClick={closeDialog}>
					<FontAwesomeIcon icon={faClose} />
				</button>
				<div className="popupModal__contentWrapper scrollbar--small">  {/* Inner scrollable content div */}
					<div className="popupModal__contentSpacing">
						{children}
					</div>
				</div>
			</div>
		</dialog>
	);
}

export default PopupModal;

// PopupModal component implementation
// ...

// Usage example:
/*
const ParentComponent = (): React.ReactElement => {
	// This reference object will hold the functions exposed by the PopupModal
	let modalRef: { openDialog: () => void, closeDialog: () => void } | null = null;

	// This function will be called by the PopupModal to provide references to its internal functions
	const handleRef = (ref: { openDialog: () => void, closeDialog: () => void }): void => {
		modalRef = ref;
	}

	// An example of how you can close the modal from outside (e.g., another button in ParentComponent)
	const handleExternalClose = (): void => {
		if (modalRef) {
			modalRef.closeDialog();
		}
	}

	return (
		<div>
			<PopupModal onRef={handleRef}>
				<h1>Modal Content</h1>
			</PopupModal>
			<button onClick={handleExternalClose}>Close Modal Externally</button>
		</div>
	);
}

Commented out for demonstration purposes. 
*/
