import React from "react"
import { AccordionData, Accordion } from "./Accordion"
import "./stylesheets/accordion.scss"
import { Image as ImageProps } from 'shared/interfaces/Image';
import StrapiImage from 'components/Strapi/Image';
import { Text } from "components/Text"
import Wrapper from "components/Strapi/Wrapper";

type TwoColumnAccordionProps = {
  sectionBgColor?: {
    selectedColor: string
  },
  accordion: {
    heading: string
    subHeading?: string
    image: ImageProps
    description?: string
    reversed?: boolean
    accordion: {
      accordion: AccordionData
    }[]
  }[]
}

export const TwoColumnAccordion = (
  props: TwoColumnAccordionProps,
): React.ReactElement => {
  const { accordion } = props
  const classPrefix = "two-column-accordion-layout"

  return (
    <Wrapper bgColor={props?.sectionBgColor?.selectedColor}>
      <div className={`accordion__container2 container container--large`}>
        {accordion.map(({ heading, subHeading, image, description, reversed, accordion }, index) => {
          const reverseClass = reversed ? `${classPrefix}__reversed` : null
          const accordion2 = accordion.map(item => ({ ...item.accordion }));

          return (
            <div key={index} className={`${classPrefix} ${reverseClass}`}>
              <div className={`${classPrefix}__column ${classPrefix}__column--content`}>
                <div className={`${classPrefix}__mobileImageWrapper mobile-show`}>
                  <StrapiImage
                    className={`${classPrefix}__image`}
                    data={image}
                  />
                </div>
                <h2>{heading}</h2>
                {subHeading && <h3 className={`${classPrefix}__subHeading`}>{subHeading}</h3>}
                {description && <Text text={description} className='description' />}
                <div className={`${classPrefix}__accordions`}>
                  <Accordion
                    key={index}
                    accordion={accordion2}
                  />
                </div>
              </div>

              <div
                className={`${classPrefix}__column ${classPrefix}__column--image desktop-show`}
              >
                <div className={`${classPrefix}__desktopImageWrapper`}>
                  <StrapiImage data={image} />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Wrapper>
  )
}
