import { useState, useCallback, useMemo, useRef} from 'react';
import { Map as Mapbox, Popup, Source, Layer, LayerProps, type MapRef} from 'react-map-gl';
{ /* @ts-expect-error - TS complaining about the JS */ }
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
{ /* @ts-expect-error - TS complaining about the JS  */ }
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { isMobile, isTablet } from 'react-device-detect';
import 'mapbox-gl/dist/mapbox-gl.css';
import './stylesheets/maps.scss';
import affiliates from 'shared/assets/data/map_data.json';
import countiesGeojson from 'shared/assets/data/counties.json';
import statesGeojson from 'shared/assets/data/states.json';
import marker from 'shared/assets/images/marker.png';

const stateLayer: LayerProps = {
  id: 'states',
  type: 'fill',
  paint: {
    'fill-outline-color': '#FFFFFF',
    'fill-color': '#FFF',
    'fill-opacity': 1
  }
};

const stateHighlightLayer: LayerProps = {
  id: 'states-highlight',
  type: 'fill',
  paint: {
    'fill-outline-color': '#FFFFFF',
    'fill-color': '#7AD0E2',
    'fill-opacity': 0
  }
};

const countiesLayer: LayerProps = {
  id: 'counties',
  source: '',
  type: 'fill',
  paint: {
    'fill-outline-color': '#FFFFFF',
    'fill-color': '#D1D1D1',
    'fill-opacity': 1
  }
};

const grantCountiesLayer: LayerProps = {
  id: 'grant-counties',
  source: '',
  type: 'fill',
  paint: {
    'fill-outline-color': '#FFFFFF',
    'fill-color': '#7AD0E2',
    'fill-opacity': 1
  }
};

// Highlighted counties
const highlightLayer: LayerProps = {
  id: 'counties-highlighted',
  type: 'fill',
  source: 'data',
  paint: {
    'fill-outline-color': '#FFFFFF',
    'fill-color': '#65b1c1',
    'fill-opacity': 1
  }
};

const grantsMarkers: LayerProps = {
  id: 'grants-markers',
  type: 'symbol',
  source: 'data',
  'layout': {
    'icon-image': 'marker',
    'icon-size': 0.15,
    'text-allow-overlap': true,
    'text-ignore-placement': true,
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
  }
}

const setZoom = () => {
  switch(true) {
    case isMobile:
      return 5.5
    case isTablet:
      return 6
    default:
     return 6.6
  }
};

// Required to stop the transpiling issue
mapboxgl.workerClass = MapboxWorker;

export const CountyMap = () => {
  const grantCities = affiliates.flatMap((aff) => {
    const list = aff.geographic_coverage.split(';');

    return list;
  });

  const mapRef = useRef<MapRef>(null);

  const [hoverInfo, setHoverInfo] = useState<any>(null);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const onHover = useCallback((event: any) => {
    const county = event.features && event.features[0];
    setHoverInfo({
      longitude: event.lngLat.lng || -77.9,
      latitude: event.lngLat.lat || 38.5,
      countyId: county && county.properties.GEOID,
      countyName: county && county.properties.NAME,
      affiliates: county ? affiliates.filter((aff: any) => {
        return aff.geographic_coverage.includes(county.properties.NAME || '')
      })[0] : []
    });

    setShowPopup(county && county.properties.GEOID);
  }, [hoverInfo]);

  const selectedCounty = hoverInfo;
  const filter = useMemo(
    () => ['in', 'GEOID', selectedCounty ? selectedCounty.countyId : ''],
    [hoverInfo]
  );
  const filter2: ['in', string, string] = useMemo(
    () => ['in', 'STATE_NAME', 'Virginia'],
    []
  );
  const filterVaCounties = useMemo(
    () => ["all",
      ['in', 'STATEFP', '51']
    ],
    []
  );
  const filterGrantCounties = useMemo(
    () => ["all",
      ['!=', 'GEOID', '51159'],
      ['!=', 'GEOID', '51600'],
      ['in', 'STATEFP', '51'],
      ['in', 'NAME', ...grantCities],
    ],
    []
  );

  const data = useMemo(() => {
    return countiesGeojson || {};
  }, [countiesGeojson]);

  const memoizedStateData = useMemo(() => {
    return statesGeojson || {};
  }, [statesGeojson]);

  if(!data || !mapRef) return <></>;

  return <div className='map-container'><Mapbox
    ref={mapRef}
    mapLib={import('mapbox-gl')}
    initialViewState={{
      longitude: -79.024902,
        latitude: 37.926868,
        zoom: setZoom()
    }}
    mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
    mapStyle="mapbox://styles/wcgcreates/cltiuygbl00f501qpd8iddsb4"
    scrollZoom={false}
    onClick={onHover}
    onLoad={() => {
      const map = mapRef.current?.getMap();

      map && map.loadImage(marker, (error: any, image: any) => {
        if (error) throw error;
        if (!map.hasImage('marker')) map.addImage('marker', image);
      })}
    }
    interactiveLayerIds={['grants-markers']}
    maxZoom={8.5}
  >
     { /* @ts-expect-error - does not like arbritrary data */ }
    <Source type='geojson' data={memoizedStateData}>
      <Layer {...stateLayer} />
      <Layer {...stateHighlightLayer} filter={filter2} />
    </Source>
    { /* @ts-expect-error - does not like arbritrary data */ }
    <Source type="geojson" id='cs' data={data}>
      <Layer {...countiesLayer} filter={filterVaCounties} />
      <Layer {...grantCountiesLayer} filter={filterGrantCounties} />
      {hoverInfo && hoverInfo.countyId && <Layer {...highlightLayer} filter={filter} />}
      <Layer {...grantsMarkers} filter={filterGrantCounties} />
    </Source>

    {hoverInfo && showPopup && (
      <Popup
        longitude={hoverInfo.longitude}
        latitude={hoverInfo.latitude}
        offset={[0, -10] as [number, number]}
        closeButton={true}
        closeOnClick={false}
        onClose={() => setShowPopup(false)}
        className="popup county-info"
      >
        <p className='popup__foundation-name'>{hoverInfo.affiliates.name}</p>
        <br />
        <a className='popup__link' href={hoverInfo.affiliates.website} target='_blank' rel='noreferrer nopener'>{hoverInfo.affiliates.website}</a>
      </Popup>)}
  </Mapbox></div>;
}

export const UsMap = () => {
  const memoizedStateData = useMemo(() => {
    return statesGeojson || {};
  }, [statesGeojson]);

  return (
    <div className='map-container'><Mapbox
      mapLib={import('mapbox-gl')}
      initialViewState={{
        longitude: -100.7821,
          latitude: 39.5501,
          zoom: 3.5
      }}
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      mapStyle="mapbox://styles/wcgcreates/cltiuygbl00f501qpd8iddsb4"
      scrollZoom={false}
      maxZoom={9}
    >
      { /* @ts-expect-error - does not like arbritrary data */ }
      <Source type='geojson' data={memoizedStateData}>
        <Layer {...stateHighlightLayer} />
      </Source>

    </Mapbox></div>
  )
}
