import React, { useState } from 'react';
import PopupModal from '../PopupModal';

import StrapiImage from 'components/Strapi/Image';
import { Image as ImageProps } from 'shared/interfaces/Image';
import Text from 'components/Text';
import Wrapper from "components/Strapi/Wrapper";

import './_index.scss';

type UserProfileType = {
  image: ImageProps;
  fullName: string;
  itemOne?: string;
  branch: string;
  itemTwo?: string;
  other?: string;
  company?: string;
  description: string;
};

type OurTeamCardsProps = {
  profile: UserProfileType[];
  bgColor?: {
    selectedColor: string
  };
};

type UserCardProps = {
  user: UserProfileType;
  onClick: (user: UserProfileType) => void;
};

export const UserCard = ({ user, onClick }: UserCardProps): React.ReactElement => {
  const { fullName, image, itemOne, itemTwo, other, company } = user;

  return (
    <div className="user-card" onClick={() => onClick(user)}>
      <div className="user-card__image-wrapper">
        <StrapiImage data={image} />
      </div>
      <div className='user-card__info'>
        <h4>{fullName}</h4>
        {itemOne && <p>{itemOne}</p>}
        {itemTwo && <p>{itemTwo}</p>}
        {company && <p>{company}</p>}
        {other && <p>{other}</p>}
      </div>
    </div>
  );
};

export const OurTeamCards = ({ profile, bgColor }: OurTeamCardsProps): React.ReactElement => {

  const branches = [...new Set(profile?.map(({ branch }) => branch))];

  const [selectedUser, setSelectedUser] = useState<UserProfileType | null>(null);
  const [modalFunctions, setModalFunctions] = useState<{ openDialog: () => void, closeDialog: () => void } | null>(null);

  const handleUserClick = (user: UserProfileType): void => {
    setSelectedUser(user);
    modalFunctions?.openDialog();
  };

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className='user-cards container container--large'>
        {branches && branches.map((branch, index) => (
          <div key={index} className='user-cards__container'>
            <h2>{branch}</h2>
            <div className='user-cards__layout scrollbar--small'>
              {profile && profile
                .filter((user) => user.branch === branch)
                .map((user, index) => (
                  <UserCard key={index} user={user} onClick={handleUserClick} />
                ))}
            </div>
          </div>
        ))}
        <PopupModal onRef={setModalFunctions}>
          {selectedUser && (
            <div className="profilePopup">
              <div className="profilePopup__bufferTop"></div>
              <div className="profilePopup__imageWrapper">
                <StrapiImage data={selectedUser.image} />
              </div>
              <div className="profilePopup__content scrollbar--large">
                <h3>{selectedUser.fullName}</h3>
                <p>{selectedUser.itemOne}</p>
                <p>{selectedUser.itemTwo}</p>
                <p>{selectedUser.company}</p>
                <p>{selectedUser.other}</p>
                <div className="profilePopup__contentDescription">
                  <Text text={selectedUser.description} />
                </div>
              </div>
              <div className="profilePopup__bufferBottom"></div>
            </div>
          )}
        </PopupModal>
      </div>
    </Wrapper>
  );
};
