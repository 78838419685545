import { Routes, Route } from 'react-router-dom';
import strapiSitemap from './strapi-sitemap.json'; // Adjust the path to your JSON file

// Template components
import DiscoverTemplate from 'pages/templates/discover';
import EngageTemplate from 'pages/templates/engage';
import GiveTemplate from 'pages/templates/give';
import RecieveTemplate from 'pages/templates/receive';
import BlogTemplate from 'pages/templates/blog';
import NewsTemplate from 'pages/templates/news';
import SpecialEventsTemplate from 'pages/templates/specialEvents';
import Video from 'pages/templates/video';
import Article from 'pages/templates/article';
import Other from 'pages/templates/other';
import Alerts from 'pages/templates/alerts';

import { NotFound } from 'pages/errors/NotFound';

import KitchenSink from 'pages/kitchen-sink';

// Object mapping collection types to their template components
const templateMapping = {
  Discover: DiscoverTemplate,
  Engage: EngageTemplate,
  Give: GiveTemplate,
  Recieve: RecieveTemplate,
  Blog: BlogTemplate,
  News: NewsTemplate,
  SpecialEvents: SpecialEventsTemplate,
  Video: Video,
  Article: Article,
  Other: Other,
  Alerts: Alerts
};

export default function allRoutes() {
  // Manual routes
  const routeArray = [
    <Route path="*" element={<NotFound message="Oops! That’s embarrassing." code="404" />} key="not-found" />,
    <Route path="/kitchen-sink" element={<KitchenSink />} key="kitchen-sink" />,
  ];

  // Initialize Set with paths from the manual routes
  const existingPaths = new Set(routeArray.map(route => route.props.path));

  // Iterate through the strapiSitemap to generate Route components
  Object.entries(strapiSitemap).forEach(([collectionType, slugs]: [string, string[]]) => {
    const TemplateComponent = templateMapping[collectionType];

    slugs.forEach((slug, index) => {
      // Skip this route if its path already exists
      if (existingPaths.has(slug)) {
        return;
      }
      const lastSection = slug.split('/').pop() || '';

      if (TemplateComponent) {
        routeArray.push(
          <Route path={slug} element={<TemplateComponent slug={slug} />} key={`${lastSection}--${index}`} />
        );
        // Add the new path to the set
        existingPaths.add(slug);
      } else {
        console.warn(`No template found for collection type: ${collectionType}`);
      }
    });
  });

  return <Routes>{routeArray}</Routes>;
}
