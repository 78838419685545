import React, { useState } from 'react';
import { PageAlertProps } from './lib/props';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-common-types';

import './stylesheets/alerts.scss';

export const PageAlert = (props: PageAlertProps): React.ReactElement => {
  const { callback, text, visible, icon, iconBgColor, link, linkName } = props;
  const [ isVisible, setIsVisible ] = useState(visible||false);

  const handleClose = () => {
    setIsVisible(false);

    if(callback) callback();
  }

  return (
    <>
    {isVisible &&
    <div className='alert alert--page'>
      <span className={`alert__icon background background--${iconBgColor}`}>
        <FontAwesomeIcon icon={['fas', icon as IconName]} />
      </span>
      <span className='alert__text'>
        { parse(text) }{ (link && linkName) && <a href={link} className="alert__link">{linkName}</a> }
      </span>
      <span className='alert__close' onClick={handleClose}>
        <FontAwesomeIcon icon={['fas', 'close']} />
      </span>
    </div>
    }
    </>
  );
};

export default PageAlert;
