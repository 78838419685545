export interface PayloadItem {
    [key: string]: any;
}

export async function fetchDataWithQueryParams(endpoint: string, queryParams: {[key: string]: any}): Promise<any> {
    const queryString = Object.keys(queryParams).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`).join('&');

    const urlWithParams = `${process.env.REACT_APP_RAILS_URL}/${endpoint}?${queryString}`;

    try {
        const response = await fetch(urlWithParams);
        if (!response.ok) {
            throw new Error(`HTTP Error. Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
        
    } catch (error) {
        console.log('Error fetching data: ', error);
        throw error;
    }

}