import React from "react";
import Wrapper from "components/Strapi/Wrapper";
import "./stylesheets/copy-blocks.scss"
import { Color } from "shared/interfaces/Color";
import { mapColor } from "shared/helpers/colorMapping";

type CardDetails =
  {
    id: number
    key: string
    value?: string
  }

type FourColumnCardProps =
  {
    id: number
    heading?: string
    headingBGColor?: Color
    contentBGColor?: Color
    details?: CardDetails[]
    subheading1?: string
    subheading1Details?: CardDetails[]
    subheading2?: string
    subheading2Details?: CardDetails[]
  }

type FourColumnTableProps =
  {
    cards: FourColumnCardProps[]
  }

const keyValueFormatter = (detail: CardDetails): string => {
  return detail.value ? `${detail.key}: ${detail.value}`: detail.key
}

export const FourColumnTable =
  (
    props: FourColumnTableProps,
  ): React.ReactElement => {
    return (
      <Wrapper>
        <div className="container container--large">
          <div className="four-column-table">
            {props.cards.map((card) => (
              <div className={`four-column-table__card`} key={card.id}>
                <div className={`four-column-table__card--heading background--${card.headingBGColor ? mapColor(card.headingBGColor.selectedColor): ''}`}>
                  <h4>{card.heading}</h4>
                </div>
                <div className={`four-column-table__card--content background--${card.contentBGColor ? mapColor(card.contentBGColor.selectedColor): ''}`}>
                <div className="four-column-table__card--content-one">
                  
                  {card.details?.map((detail)=>(
                    <div key={detail.id} className="four-column-table__card--content-one--container">
                      <p>{detail.key}</p>
                      <p>{detail.value}</p>
                    </div>
                  ))}
                </div>
                  <hr />
                <div className="four-column-table__card--content-two">
                  <div className="four-column-table__card--content-two--container">
                  <div className="four-column-table__card--content-two--content">
                  <h3>{card.subheading1}</h3>
                  {card.subheading1Details?.map((detail)=>(
                    <div className="four-column-table__card--content-two--content-container" key={detail.id}>
                      <p>{detail.key}</p>
                      <p>{detail.value}</p>
                    </div>
                  ))}
                  <hr />
                  </div>
                  <div className="four-column-table__card--content-two--placeholder"></div>
                  </div>
                </div>
                <div className="four-column-table__card--content-three">
                <h3>{card.subheading2}</h3>
                {card.subheading2Details?.map((detail)=>(
                  <p key={detail.id}>{keyValueFormatter(detail)}</p>
                ))}
              </div>
              </div>
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
      
    )
  }
