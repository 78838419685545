import { faPhone, faFax, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Wrapper from "components/Strapi/Wrapper";

import './_index.scss';

interface Image {
  image: {
    data: {
      attributes: {
        url: string;
        name: string;
        alternativeText?: string;
      }
    }
  }
  lazy?: boolean;
}

interface Link {
  href: string;
  target?: '_blank';
  rel?: 'noopener noreferrer nofollow' | 'noopener noreferrer' | 'noopener' | 'noreferrer' | 'nofollow';
  ariaLabel?: string;
}

interface IconBannerProps {
  componentId?: string;
  className?: string;
  variation: 'Large' | 'Small' | 'ContactInfo';
  bgColor?: {
    selectedColor: string
  },
  heading?: string;
  headingHighlight?: string;
  icons?: Image[];  // this is for the multiple icons in variations Large and Small
  icon?: Image;  // this is for the single icon in variation ContactInfo
  directions1?: string;
  directions2?: string;
  address?: string;
  contactNumber?: string;
  faxNumber?: string;
  email?: string;
  connectIcon?: Image;  // For the "Connect" column icon in ContactInfo variation
  connectLink?: Link;
}

const IconBanner = ({
  className,
  variation,
  bgColor,
  heading,
  headingHighlight,
  icons,
  icon,
  directions1,
  directions2,
  contactNumber,
  faxNumber,
  email,
  connectIcon,
  connectLink
}: IconBannerProps) => {

  const renderHighlightedHeading = () => {
    if (!heading || !headingHighlight) return null;
    const parts = heading.split(new RegExp(`(${headingHighlight})`, 'gi'));
    return <h2 className="icon-banner__heading">{parts.map((part, i) => part.toLowerCase() === headingHighlight.toLowerCase() ? <b key={i} className="icon-banner__highlight">{part}</b> : part)}</h2>;
  };

  const iconImg = icon?.image?.data?.attributes;
  const connectIconImg = connectIcon?.image?.data?.attributes;

  return (
    <Wrapper bgColor={bgColor?.selectedColor} className={`${className} icon-banner icon-banner--${variation}`}>
      <div className={`icon-banner icon-banner--${variation}`}>
        <div className="icon-banner__container">
          {variation !== 'ContactInfo' && (headingHighlight ? renderHighlightedHeading() : <h2 className="icon-banner__heading">{heading}</h2>)}

          {variation !== 'ContactInfo' &&
            <div className="icon-banner__icons-wrapper">
              {icons?.map((icon, i) => {
                const img = icon?.image?.data?.attributes;
                return img ? (<img key={`${variation}-${i}`} src={img.url} alt={img?.alternativeText || `${variation} icon`} loading={icon?.lazy ? 'lazy' : 'eager'} className="icon-banner__icon" />) : null;
              })}
            </div>
          }

          {variation === 'ContactInfo' && (
            <>
              {iconImg && (
                <div className="icon-banner__single-icon">
                  <img src={iconImg.url} alt={iconImg.alternativeText || 'Contact Info Icon'} loading={icon.lazy ? 'lazy' : 'eager'} />
                </div>
              )}
              <div className='icon-banner__inner-grid'>
                {directions1 && (
                  <div className="icon-banner__directions">
                    <h4 className="icon-banner__sub-heading">Directions</h4>
                    <h5>
                      <a className='icon-banner__anchor' href={`http://maps.google.com/?q=${directions1}${directions2}`}
                        rel="noreferrer" target="_blank">
                        {directions1}
                        <br />
                        {directions2}
                      </a>
                    </h5>
                  </div>
                )}

                {(contactNumber || faxNumber || email) && (
                  <div className="icon-banner__contact-numbers">
                    <h4 className="icon-banner__sub-heading">Contact</h4>
                    {contactNumber &&
                      <a className="icon-banner__text icon-banner__text--phone" href={`tel:${contactNumber}`}>
                        <FontAwesomeIcon icon={faPhone} />{contactNumber}
                      </a>
                    }
                    {faxNumber &&
                      <a className="icon-banner__text icon-banner__text--phone2" href={`tel:${faxNumber}`}>
                        <FontAwesomeIcon icon={faFax} />{faxNumber}
                      </a>
                    }
                    {email &&
                      <a className="icon-banner__text icon-banner__text--email" href={`mailto:${email}`}>
                        <FontAwesomeIcon icon={faEnvelope} />EMAIL US
                      </a>
                    }
                  </div>
                )}

                {connectIconImg && connectLink?.href && (
                  <div className="icon-banner__connect">
                    <h4 className="icon-banner__sub-heading">Connect</h4>
                    <a href={connectLink.href} target={connectLink.target} rel={connectLink.rel} aria-label={connectLink.ariaLabel}>
                      <img src={connectIconImg.url} alt={connectIconImg.alternativeText || 'Connect Icon'} />
                    </a>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default IconBanner;