import React from 'react';
import './_index.scss';

import { Button } from 'components/Buttons';

interface ResourceCardProps {
  datePosted: string;
  title: string;
  subTitle: string;
  description: string;
  file: any;
}

const ResourceCard = ({ datePosted, title, subTitle, description, file }: ResourceCardProps): React.ReactElement => {
  let fileExtension = '';
  if (file?.data?.attributes?.url) {
    const urlParts = file.data.attributes.url.split('.');
    fileExtension = urlParts[urlParts.length - 1];
  }

  return (
    <div className='container container--large'>
      <div className='resourceCard'>
        <span className='resourceCard__date'>Posted {datePosted}</span>

        <h3 className='resourceCard__title'>{title}</h3>
        <h4 className='resourceCard__subTitle'>{subTitle}</h4>
        <p className='resourceCard__description'>{description}</p>
        <div className='resourceCard__buttons'>
          {file?.data?.attributes?.url &&
            <Button
              link={file.data.attributes.url}
              icon={['fas', 'download']}
              text={`Download ${fileExtension}`}
              customClassName='resourceCard__buttonsBtn button--btn_icon'
            />
          }
          <Button
            icon={['fas', 'share-alt']}
            text='Share'
            customClassName='resourceCard__buttonsBtn button--btn_icon'
          />
        </div>
      </div>
    </div>
  )
};

export { ResourceCard }