import React from 'react';
import './stylesheets/_index.scss';

import { H1TextIntroOneColumn, IntroProps } from '../CopyBlocks/Intro';

interface VolunteerOpportunityColumn {
  fieldNameOrPath: string;
  value: string | null;
}

interface VolunteerOpportunitiesProps {
  h1SingleColumnIntro: IntroProps
  opportunities: {
    columns: VolunteerOpportunityColumn[];
  }[];
}

export const VolunteerOpportunities = ({ h1SingleColumnIntro, opportunities }: VolunteerOpportunitiesProps): React.ReactElement => {

  const transformData = (): VolunteerOpportunityColumn[][] => {
    return opportunities.map(opportunity =>
      opportunity.columns
        .filter(column =>
          ['Primary_Impact__c', 'HOC__Managing_Organization_Name__c', 'HOC__Volunteer_Opportunity__r.Name', 'HOC__Location_Type__c', 'HOC__Start_Date_Time__c']
            .includes(column.fieldNameOrPath)
        )
        .map(column => {
          switch (column.fieldNameOrPath) {
            case 'Primary_Impact__c': return { fieldNameOrPath: 'Name', value: column.value };
            case 'HOC__Managing_Organization_Name__c': return { fieldNameOrPath: 'Organization', value: column.value };
            case 'HOC__Volunteer_Opportunity__r.Name': return { fieldNameOrPath: 'Opportunity', value: column.value };
            case 'HOC__Location_Type__c': return { fieldNameOrPath: 'Where', value: column.value };
            case 'HOC__Start_Date_Time__c': return { fieldNameOrPath: 'When', value: column.value };
            default: return column;
          }
        })
    );
  };

  const transformedOpportunities = transformData();

  // Dynamically gather field names from the first transformed item, assuming all items have a similar structure
  const fieldNames = transformedOpportunities.length > 0 ? transformedOpportunities[0].map(column => column.fieldNameOrPath) : [];

  return (
    <div className='volunteer'>
      {h1SingleColumnIntro && (
        <H1TextIntroOneColumn
          title={h1SingleColumnIntro.title}
          desc={h1SingleColumnIntro.desc}
          cta={h1SingleColumnIntro.cta}
        />
      )}
      <div className='volunteer--mobile'>
        {transformedOpportunities.map((opportunity, rowIndex) => (
          <div key={`entry-${rowIndex}`} className="volunteer--mobile__entry">
            {opportunity.map(({ fieldNameOrPath, value }, colIndex) => (
              <div key={`details-${rowIndex}-${colIndex}`} className="volunteer--mobile__details-container">
                <div className='volunteer--mobile__details'>
                  <p className='volunteer--mobile__label'>{fieldNameOrPath}:</p>
                  <p className='volunteer--mobile__content'>{value}</p>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className='volunteer--desktop'>
        <table>
          <thead>
            <tr>
              {fieldNames.map((fieldName, index) => (
                <th key={`header-${index}`}>{fieldName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {transformedOpportunities.map((opportunity, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                {opportunity.map(({ value }, colIndex) => (
                  <td key={`cell-${rowIndex}-${colIndex}`}>{value}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
