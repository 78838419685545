import React from 'react';
import { Button } from 'components/Buttons';
import { ColorDivider } from 'components/Dividers/Dividers';
import { SearchRedirectInput } from 'components/Inputs'

import './stylesheets/footer.scss';

type NavGroupProps = {
  header?: string;
  children: any;
  link?: string;
}

const FooterNavGroup = ({ header, children, link }: NavGroupProps): React.ReactElement => {
  const Wrapper = link ? 'a' : 'div';
  const wrapperProps = link ? { href: link } : {};

  return (
    <Wrapper {...wrapperProps}>
      {header && <h6>{header}</h6>}
      {children}
    </Wrapper>
  );
};

export const Footer = (): React.ReactElement => {
  const copyrightYear = new Date().getFullYear();

  const anchorClassName = 'button--footer-list';

  return (
    <div className='footerWrapper'>
      <ColorDivider />
      <footer className='footer'>
        <div className='footer__header'>
          <div className='actions'>
            <SearchRedirectInput position='footer' />
            <Button
              text='START A FUND'
              customClassName='button--footer'
              link='/give/start-a-fund'
            />
          </div>
        </div>
        <div className='footer__body'>
          <div className='sub-nav-groups sub-nav-groups--border-right'>
            <FooterNavGroup
              header='DISCOVER'
              link='/discover'
            >
              <ul>
                <li><a href='/discover/community' className={anchorClassName}>Your Community</a></li>
                <li><a href='/discover/cf' className={anchorClassName}>Your Community Foundation</a></li>
                <li><a href='/discover/faces-of-philanthropy' className={anchorClassName}>Faces of Philanthropy</a></li>
              </ul>
            </FooterNavGroup>
            <FooterNavGroup
              header='ENGAGE'
              link='/engage'
            >
              <ul>
                <li><a href='/engage/share-treasure/cause' className={anchorClassName}>Find a Cause</a></li>
                <li><a href='/engage/share-time' className={anchorClassName}>Share Your Time</a></li>
                <li><a href='/engage/share-talent' className={anchorClassName}>Share Your Talent</a></li>
                <li><a href='/engage/share-treasure' className={anchorClassName}>Share Your Treasure</a></li>
              </ul>
            </FooterNavGroup>
            <FooterNavGroup
              header='GIVE'
              link='/give'
            >
              <ul>
                {/* Needs Updated Link For Now Will Take To Give Homepage */}
                <li><a href='/give' className={anchorClassName}>Who We Serve</a></li>
                <li><a href='/give/what' className={anchorClassName}>What We Do</a></li>
                <li><a href='/give/learn' className={anchorClassName}>Learn About Giving</a></li>
                <li><a href='/give/start-a-fund' className={anchorClassName}>My Fund</a></li>
                <li><a href='/give' className={anchorClassName}>Professional Advisors</a></li>
              </ul>
            </FooterNavGroup>
            <FooterNavGroup
              header='RECEIVE'
              link='/receive'
            >
              <ul>
                {/* Needs Updated Link For Now Will Take To Give Homepage */}
                <li><a href='/receive/nonprofits-foundations' className={anchorClassName}>Resource Center</a></li>
                <li><a href='/receive/nonprofits-foundations/grants' className={anchorClassName}>Grantmaking</a></li>
                <li><a href='/receive/scholars-educators/students' className={anchorClassName}>Scholarships</a></li>
                <li><a href='/receive/scholars-educators/educators/excellence' className={anchorClassName}>Awards</a></li>
              </ul>
            </FooterNavGroup>
            <FooterNavGroup
              header="WHAT'S NEW"
              link='/blog'
            >
              <ul>
                {/* Needs Updated Link For Now Will Take To Give Homepage */}
                <li><a href='/blog' className={anchorClassName}>Blogs</a></li>
                <li><a href='/event-calendar' className={anchorClassName}>Events</a></li>
                <li><a href='/news' className={anchorClassName}>News</a></li>
                <li><a href='/resource-library' className={anchorClassName}>Resource Library</a></li>
              </ul>
            </FooterNavGroup>
          </div>
          <div className='sub-nav-groups sub-nav-groups--vertical'>
            <FooterNavGroup
              header='DONATE'
            >
              <ul>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/donate' target='_blank' rel="noreferrer" className={anchorClassName}>Richmond Area Funds</a></li>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/donate/list?sorg_id=1000' target='_blank' rel="noreferrer" className={anchorClassName}>Gloucester Funds</a></li>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/donate/list?sorg_id=1001' target='_blank' rel="noreferrer" className={anchorClassName}>Mathews Funds</a></li>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/donate/list?sorg_id=1002' target='_blank' rel="noreferrer" className={anchorClassName}>River Counties Funds</a></li>
              </ul>
            </FooterNavGroup>
            <FooterNavGroup
              header='ACCESS MY FUND'
            >
              <ul>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/fundmanager' target='_blank' rel="noreferrer" className={anchorClassName}>Richmond CF</a></li>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/fundmanager/list?sorg_id=1000' target='_blank' rel="noreferrer" className={anchorClassName}>Gloucester CF</a></li>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/fundmanager/list?sorg_id=1001' target='_blank' rel="noreferrer" className={anchorClassName}>Mathews CF</a></li>
                <li><a href='https://tcfrichmond.fcsuite.com/erp/fundmanager/list?sorg_id=1002' target='_blank' rel="noreferrer" className={anchorClassName}>River Counties CF</a></li>
              </ul>
            </FooterNavGroup>
          </div>
        </div>
        <div className='footer__copyright'>
          <div>
            <h6>LOCATION</h6>
            <ul>
              <li><a href='https://www.google.com/maps/place/3409+W+Moore+St,+Richmond,+VA+23230' target='_blank' rel="noreferrer" className={anchorClassName}>3409 Moore Street<br /> Richmond, VA 23230</a></li>
            </ul>
          </div>
          <div>
            <h6>MAIL CHECKS TO:</h6>
            <ul>
              <li>Community Foundation for a greater Richmond <br /> P.O. Box 49044 <br /> Baltimore, MD 21297-9044</li>
            </ul>
          </div>
          <div>
            <h6>CONTACT US:</h6>
            <ul>
              <li>(804) 330-7400 <br /> <a className={anchorClassName} href="mailto:info@cfrichmond.org">info@cfrichmond.org</a></li>
            </ul>
          </div>
          <div>
            <h6><a className={anchorClassName} href='/sitemap'>SITEMAP</a> | <a className={anchorClassName} href='/policies'>CONDITIONS AND POLICIES</a></h6>
            <ul>
              <li>
                &copy; {copyrightYear} Community Foundation Inc. <br />
                EIN: 23-7009135 <br />
                All rights reserved
              </li>
            </ul>
          </div>
          <nav className='nav--social'>
            <ul>
              <li><Button icon='instagram' iconOnly={true} iconPrefix='fab' link='https://www.instagram.com/cfrichmondva/' /></li>
              <li><Button icon='facebook' iconOnly={true} iconPrefix='fab' link='https://www.facebook.com/cfrichmondva' /></li>
              <li><Button icon='linkedin' iconOnly={true} iconPrefix='fab' link='https://www.linkedin.com/company/the-community-foundation/' /></li>
              <li><Button icon='vimeo' iconOnly={true} iconPrefix='fab' link='https://vimeo.com/cfrichmondva' /></li>
            </ul>
          </nav>
        </div>
      </footer>
    </div>
  )
};
