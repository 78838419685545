import React, { useState } from 'react';
import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect'
import Wrapper from "components/Strapi/Wrapper";

import { Button } from 'components/Buttons'

import StrapiImage from 'components/Strapi/Image';
import { Image as ImageProps } from 'shared/interfaces/Image';

import 'pure-react-carousel/dist/react-carousel.es.css';
import './_index.scss';

interface ThreeColumnImageCarouselProps {
  image: ImageProps[];
  bgColor?: {
    selectedColor: string
  };
}

const ThreeColumnImageCarousel = ({ image, bgColor }: ThreeColumnImageCarouselProps): React.ReactElement => {
  // Add empty slide at the beginning by destructuring image prop and grabbing the url
  const modifiedImages = [{ image: { data: { attributes: { url: "" } } }, lazy: true }, ...image];

  const [currentIndex, setCurrentIndex] = useState(0); // Start at index 0 (empty slide)
  const [centerIndex, setCenterIndex] = useState(1); // Center starts at index 1

  const handleNext = (targetIndex?: number) => {
    setCurrentIndex((prev) => {
      let nextIndex = typeof targetIndex !== 'undefined' ? targetIndex : prev + 1;
      // Loop to the start if reached the end
      if (nextIndex >= modifiedImages.length) {
        nextIndex = 0;
      }
      // Set centerIndex for next
      const newCenterIndex = nextIndex + 1 >= modifiedImages.length ? 0 : nextIndex + 1;
      setCenterIndex(newCenterIndex);
      return nextIndex;
    });
  };

  const handleBack = (targetIndex?: number) => {
    setCurrentIndex((prev) => {
      let nextIndex = typeof targetIndex !== 'undefined' ? targetIndex : Math.max(prev - 1, 0);
      if (nextIndex === 0 && prev === 0) {
        setCenterIndex(modifiedImages.length - 1);
        nextIndex = modifiedImages.length - 1;
      } else if (nextIndex === 0) {
        setCenterIndex(1);
      } else {
        const centeredBackIndex = Math.min(nextIndex + 1, modifiedImages.length - 1);
        setCenterIndex(centeredBackIndex);
      }
      return nextIndex;
    });
  };


  const CustomDotGroup = (): React.ReactElement => {
    const totalSlides = modifiedImages.length;

    const isActive = (i: number) => {
      return i === currentIndex; // Only the dot corresponding to the current index is active
    };

    const handleDotClick = (i: number) => {
      handleNext(i);
    };

    return (
      <div className="three-column-image-carousel__custom-dots">
        {Array.from({ length: totalSlides - 1 }).map((_, i) => (
          <button
            key={i}
            onClick={() => handleDotClick(i)}
            className={`carousel__custom-dot ${isActive(i) ? 'carousel__custom-dot--selected' : ''}`}
          />
        ))}
      </div>
    );
  };

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className="three-column-image-carousel container container--large">
        <div className="three-column-image-carousel__desktop">
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={modifiedImages.length}
            visibleSlides={3}
            currentSlide={currentIndex}
            dragEnabled={false}
          >
            <Slider className="three-column-image-carousel__slider">
              {modifiedImages.map((image, index) => (
                <Slide index={index} key={index} innerClassName={`three-column-image-carousel${index === centerIndex ? '__center' : '__adjacent'}`}>
                  <div className="three-column-image-carousel__img-wrapper">
                    {index !== 0 &&
                      <StrapiImage data={image} className="three-column-image-carousel__img" />
                    }
                    {index != 0 && index != centerIndex && <div className="three-column-image-carousel__overlay"></div>}
                  </div>
                </Slide>
              ))}
            </Slider>
            <div className="three-column-image-carousel__controls">
              <Button onClick={() => handleBack()} customClassName="button--arrow button--shadow three-column-image-carousel__button--back">
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
              <CustomDotGroup />
              <Button onClick={() => handleNext()} customClassName="button--arrow button--shadow three-column-image-carousel__button--next">
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          </CarouselProvider>
        </div>
        <div className="three-column-image-carousel__mobile">
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={125}
            totalSlides={image.length}
            dragEnabled={isMobile}
          >
            <Slider className="three-column-image-carousel__slider">
              {image.map((image, index) => (
                <Slide index={index} key={index}>
                  <div className="three-column-image-carousel__img-wrapper">
                    <StrapiImage data={image} className="three-column-image-carousel__img" />
                  </div>
                </Slide>
              ))}
            </Slider>
            <DotGroup className="three-column-image-carousel__dots" />
          </CarouselProvider>
        </div>
      </div>
    </Wrapper>
  );
};

export default ThreeColumnImageCarousel;
