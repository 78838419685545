import React from "react";
import { Image } from "shared/interfaces/Image";
import { Button as IButton } from "shared/interfaces/Button";
import StrapiButton from "components/Strapi/Button";
import StrapiImageComponent from "components/Strapi/Image";
import Wrapper from "components/Strapi/Wrapper";
import Text from 'components/Text';
import "./stylesheets/image-card.scss";

type ImageCardProps = {
  title: string;
  imageUrl: Image;
  description?: string;
  cta: IButton[];
}

type ImageCardColumnProps = {
  cards: ImageCardProps[];
  variation?: 'TWO_COLUMN' | 'THREE_COLUMN' | 'DEFAULT';
  bgColor?: {
    selectedColor: string;
  };
}

type KeyValueLookup = {
  [key: string]: string;
};

export const ImageCardColumn = (props: ImageCardColumnProps): React.ReactElement => {
  const { cards, variation, bgColor } = props;

  const variationLookup: KeyValueLookup = {
    TWO_COLUMN: "image-cards-two",
    THREE_COLUMN: "image-cards-three",
    DEFAULT: ""
  }

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className={variation ? `image-cards image-cards--${variationLookup[variation]}` : "image-cards"}>
        {cards.map(({ title, imageUrl, description, cta }, index) => {
          const cardClass = `image-cards__card${description ? "" : "--alt"}`;
          const customCardClass = variation ? `${cardClass} ${variationLookup[variation]}__${cardClass}` : cardClass;

          return (
            <div key={index} className={customCardClass}>
              <StrapiImageComponent data={imageUrl} />
              <div className="image-cards__card__content">
                <h2>{title}</h2>
                <Text text={description} />
                {cta.map((button, index) => {
                  return (
                    <StrapiButton key={index} data={button} />
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </Wrapper>
  )
}
