import React, { useState } from 'react';
import { Button } from 'components/Buttons';
import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel';
import { isMobile } from 'react-device-detect'
import Wrapper from "components/Strapi/Wrapper";
import StrapiImage from 'components/Strapi/Image';
import { Image as ImageProps } from 'shared/interfaces/Image';

import './stylesheets/tabbed-carousel.scss';

type TabbedCarouselProps = {
  header?: string;
  tabs: {
    id: number;
    buttonHeader: string;
    image: ImageProps;
    storyHeader: string;
    content: string;
  }[];
  bgColor?: {
    selectedColor: string
  };
  variation: 'left_aligned' | 'brackets';
}

export const TabbedCarousel = (props: TabbedCarouselProps): React.ReactElement => {
  const { header, bgColor, tabs, variation } = props;
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className={`tabbed-carousel tabbed-carousel--${variation}`}>
        <div className={`tabbed-carousel__container container container--small`}>
          {header && <h3 className={`tabbed-carousel__heading`}>{header}</h3>}

          {/* Tabs for both Mobile and Desktop */}
          <div className={`tabbed-carousel__tabs`}>
            {tabs?.map(({ buttonHeader }, index) => (
              <Button
                key={index}
                text={buttonHeader}
                customClassName={`button--tabbed-carousel ${index === selectedTab ? 'button--tabbed-carousel__active' : ''}`}
                onClick={() => setSelectedTab(index)}
              />
            ))}
          </div>
          <div className='tabbed-carousel__content'>
            <CarouselProvider
              naturalSlideWidth={3}
              naturalSlideHeight={3}
              totalSlides={tabs.length}
              currentSlide={selectedTab}
              isIntrinsicHeight={true}
              dragEnabled={isMobile}
            >
              <Slider className='tabbed-carousel__slider-container'>
                {tabs.map(({ storyHeader, content, image }, index) => (
                  <Slide index={index} key={index} innerClassName='tabbed-carousel__innerSlider'>
                    {variation === 'left_aligned' &&
                      <div className='tabbed-carousel__image'>
                        <StrapiImage data={image} />
                      </div>
                    }
                    <div className='tabbed-carousel__content-container'>
                      <h4 className='tabbed-carousel__title'>{storyHeader}</h4>
                      <p className='tabbed-carousel__description'>{content}</p>
                    </div>
                  </Slide>
                ))}
              </Slider>
              <div className='tabbed-carousel__dots-container'>
                {tabs.map(({ id }, index) => (
                  <Dot key={id} slide={index} className="tabbed-carousel__dot" />
                ))}
              </div>
            </CarouselProvider>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
