
import React from 'react'
import ColumnCards from '../../components/ColumnCards';
import { IntroH1, H1TextIntroOneColumn } from 'components/CopyBlocks/Intro';

import ThreeColumnImageCarousel from 'components/ThreeColumnImageCarousel';
import FocusCarousel from 'components/FocusCarousel';
import PhotoCollage from 'components/Collages/PhotoCollage';
import './_index.scss'
import sampleData from "./sampleData/sampleData.json";
import { TwoColumnAccordion } from 'components/Accordion/TwoColumnAccordion';
import { AccordionWrapper, MegaAccordion } from 'components/Accordion';
import { TabbedNav, Tab } from 'components/TabbedNav';
import PageAlert from 'components/Alerts/PageAlert';
import StandardHero from 'components/Hero/StandardHero';
import { CountyMap, UsMap } from 'components/Maps/Map';
import { VolunteerOpportunities } from 'components/RSSFeed';
import ListView from 'components/ListViews/ListView';

import pageAlertData from 'shared/test/alerts/page-alert.json';
import { ImageCardColumn } from 'components/ImageCard/ImageCardColumn';
import { VideoPlayerWithWrapper } from 'components/VideoPlayer/VideoPlayer';
import twoColumnCardData from 'shared/test/column-cards/column-cards-two-column.json';
import threeColumnCardData from 'shared/test/column-cards/column-cards-three-column.json';
import fourColumnCardData from 'shared/test/column-cards/column-cards-four-column.json';
import fiveColumnCardData from 'shared/test/column-cards/column-cards-five-column.json';
import twoColumnCardDataND from 'shared/test/column-cards/column-cards-two-column-nd.json';
import threeColumnCardDataND from 'shared/test/column-cards/column-cards-three-column-nd.json';
import fourColumnCardDataND from 'shared/test/column-cards/column-cards-four-column-nd.json';
import fiveColumnCardDataND from 'shared/test/column-cards/column-cards-five-column-nd.json';
import h1TextIntroOneColumnData from 'shared/test/copyBlocks/h1-intro-one-column.json';
import homepageHeroData from 'shared/test/hero/homepage-hero.json';
import { OurTeamCards } from 'components/OurTeamCards';
import rssSampleData from 'shared/test/rssTableSample/rssTableSampleData.json';

// Store your components in this file for testing purposes -- this is the "kitchen sink" of components
const KitchenSink = (): React.ReactElement => {
  const {
    threeColumnImageCarouselSampleData,
    focusCarouselSampleData,
    sampleCards,
    sampleTwoColumnAccordion,
    accordion,
    singleColumn,
    vimeoVideo,
    youtubeVideo,
    photoCollages,
    ourTeamCards,
  } = sampleData;

  const tabbedNavData = {
    activeTab: '/about-us',
    tabs: {
      data: {
        attributes: {
          tabs: sampleData.tabbedNavTabs as Tab[]
        }
      }
    }
  };

  return (
    <div className='kitchen-sink'>
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Scholarship Widget</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <div className='container container--large'>
        <ListView
          objectType='CFR_Scholarships__c'
          listViewID='00BNw000000QAgTMAW'
          displayOptions='Scholarships'
          recordsPerPage='5'
        />
      </div>
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Mapbox</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <div className='container container--large'>
        <CountyMap />
        <UsMap />
      </div>
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Hero</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <div className='container container--large'>
        <StandardHero {...homepageHeroData} />
      </div>
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Page Alert</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      {pageAlertData.map((data, i) => {
        return <div key={i} className='container container--large'>
          <PageAlert {...data} />
        </div>
      })}
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Photo Collages</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>3 Column - Right Aligned - With Logo BG</h2>
      </div>
      <PhotoCollage {...photoCollages[0]} />
      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>3 Column - Left Aligned</h2>
      </div>
      <PhotoCollage {...photoCollages[1]} />
      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>4 Column - Right Aligned</h2>
      </div>
      <PhotoCollage {...photoCollages[2]} />
      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>4 Column - Left Aligned</h2>
      </div>
      <PhotoCollage {...photoCollages[3]} />
      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>5 Column - Right Aligned</h2>
      </div>
      <PhotoCollage {...photoCollages[4]} />
      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>5 Column - Left Aligned</h2>
      </div>
      <PhotoCollage {...photoCollages[5]} />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Three Column Image Carousel</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <ThreeColumnImageCarousel image={threeColumnImageCarouselSampleData.image} />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Focus Carousel</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <FocusCarousel {...focusCarouselSampleData} />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Column Cards</h1>
        <hr className='kitchen-sink__hr'></hr>
        <h2 className='kitchen-sink__h2'>3 Columns:</h2>
      </div>
      <ColumnCards
        cards={sampleCards.slice(0, 3)}
        columnType="threeColumnImage"
        imageType="Image"
        isMobileCarousel={true}
      />
      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>3 Columns - icons:</h2>
      </div>
      <ColumnCards
        cards={sampleCards.slice(0, 3)}
        columnType="threeColumnImage"
        imageType="Icon"
      />

      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>4 Columns:</h2>
      </div>
      <ColumnCards
        cards={sampleCards.slice(0, 4)}
        columnType="fourColumnImage"
        imageType="Image"
      />
      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>4 Columns - icons:</h2>
      </div>
      <ColumnCards
        cards={sampleCards.slice(0, 4)}
        columnType="fourColumnImage"
        imageType="Icon"
      />
      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>5 Columns:</h2>
      </div>
      <ColumnCards
        cards={sampleCards.slice(0, 5)}
        columnType="fiveColumnImage"
        imageType="Image"
      />
      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>Horizontal:</h2>
      </div>
      <ColumnCards
        cards={sampleCards.slice(0, 3)}
        columnType="horizontalImage"
        imageType="Image"
      />
      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>Horizontal - icons:</h2>
      </div>
      <ColumnCards
        cards={sampleCards.slice(0, 3)}
        columnType="horizontalImage"
        imageType="Icon"
      />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Accordion</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <AccordionWrapper accordion={accordion} />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Two Column Accordion With Image</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <TwoColumnAccordion
        accordion={sampleTwoColumnAccordion.accordionData}
      />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Mega Accordion</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <MegaAccordion
        accordion={accordion}
      />
      <div className='container container--large kitchen-sink__tabbedNav'>
        <h1 className='kitchen-sink__h1'>Tabbed Nav</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <TabbedNav
        {...tabbedNavData}
      />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>2 Column Image Block</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <ImageCardColumn
        cards={twoColumnCardData.cards} variation="TWO_COLUMN"
      />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>3 Column Image Block</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <ImageCardColumn
        cards={threeColumnCardData.cards} variation='THREE_COLUMN'
      />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>4 Column Image Block</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <ImageCardColumn
        cards={fourColumnCardData.cards} variation='DEFAULT'
      />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>5 Column Image Block</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <ImageCardColumn
        cards={fiveColumnCardData.cards} variation='DEFAULT'
      />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>2 Column Image Block Without Description</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <ImageCardColumn
        cards={twoColumnCardDataND.cards} variation='TWO_COLUMN'
      />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>3 Column Image Block Without Description</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <ImageCardColumn
        cards={threeColumnCardDataND.cards} variation='THREE_COLUMN'
      />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>4 Column Image Block Without Description</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <ImageCardColumn
        cards={fourColumnCardDataND.cards} variation='DEFAULT'
      />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>5 Column Image Block Without Description</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <ImageCardColumn
        cards={fiveColumnCardDataND.cards} variation='DEFAULT'
      />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Single Column</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <IntroH1 variation={singleColumn.customClassName} desc={singleColumn.desc} title={singleColumn.title} />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>H1 Text Intro One Column CTA</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <H1TextIntroOneColumn title={h1TextIntroOneColumnData.title} desc={h1TextIntroOneColumnData.desc} cta={h1TextIntroOneColumnData.cta} />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>H1 Text Intro One Column</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <H1TextIntroOneColumn title={h1TextIntroOneColumnData.title} desc={h1TextIntroOneColumnData.desc} />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Video Player</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>Vimeo Video</h2>
      </div>
      <VideoPlayerWithWrapper {...vimeoVideo} />
      <div className='container container--large'>
        <h2 className='kitchen-sink__h2'>Youtube Video</h2>
      </div>
      <VideoPlayerWithWrapper {...youtubeVideo} />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Our Team Cards</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <OurTeamCards profile={ourTeamCards.profile} />
      <div className='container container--large'>
        <h1 className='kitchen-sink__h1'>Volunteer Opportunities</h1>
        <hr className='kitchen-sink__hr'></hr>
      </div>
      <VolunteerOpportunities opportunities={rssSampleData.opportunities} h1SingleColumnIntro={rssSampleData.h1SingleColumnIntro} />
    </div>
  )
}

export default KitchenSink
