import { fetchDataWithQueryParams, PayloadItem } from "shared/lib/api/listViews";
import React, { useEffect, useState } from 'react';
import { FiveColumnTable } from "./FiveColumnTable";
import { Scholarships } from 'components/Scholarships/Scholarships';

interface ListViewProps {
  objectType: string;
  listViewID: string;
  displayOptions: "Five_Column_Table" | 'Scholarships' | 'Calendar';
  attributeOverride?: string;
  urlAttribute?: string;
  recordsPerPage?: string;
  bgColor?: {
    selectedColor: string
  }
  sortColumnIndex?: number;
}

const ListView: React.FC<ListViewProps> = ({ objectType, listViewID, displayOptions, attributeOverride, urlAttribute, recordsPerPage, bgColor, sortColumnIndex }) => {
  const [records, setRecords] = useState<PayloadItem[] | []>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const results = await fetchDataWithQueryParams('list_view_records', {
          'object_type': objectType,
          'listview_id': listViewID,
        });
        setRecords(results);
      } catch (error) {
        setError('There are currently no postings available for this category');
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [objectType, listViewID]);

  const renderComponent = () => {
    if (!records) return <></>;
    switch (displayOptions) {
      case 'Five_Column_Table':
        return <FiveColumnTable data={records} bgColor={bgColor} columnOverrides={attributeOverride} urlAttribute={urlAttribute} recordsPerPage={recordsPerPage} sortColumnIndex={sortColumnIndex} />
      case 'Scholarships':
        return <Scholarships data={records} bgColor={bgColor} recordsPerPage={recordsPerPage} />
      default:
        return <></>;
    }
  }



  if (isLoading) return <div>Loading...</div>;
  if (error) return <div><h2 className="error-message">{error}</h2></div>;

  return (renderComponent());
};

export default ListView;
