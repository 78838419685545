import React, { useState } from "react";
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { Button } from '../Buttons/index'

import StrapiImage from 'components/Strapi/Image';
import StrapiButton from 'components/Strapi/Button';
import Wrapper from "components/Strapi/Wrapper";

import { Button as ButtonProps } from 'shared/interfaces/Button';
import { Image as ImageProps } from 'shared/interfaces/Image';
import Text from 'components/Text';

import 'pure-react-carousel/dist/react-carousel.es.css';
import './stylesheets/image-carousel-50-50.scss'

type ImageCarousel5050Props = {
  tabs: {
    id: number;
    image: ImageProps
    title: string;
    description: string;
    cta: ButtonProps;
  }[]
  bgColor?: {
    selectedColor: string
  };
  imageAlignment: string;
}

interface CustomDotGroupProps {
  setCurrentIndex: (index: number) => void;
  totalSlides: number;
  currentIndex: number;  // Add this prop to know which slide is currently active
}

const CustomDotGroup = ({ setCurrentIndex, totalSlides, currentIndex }: CustomDotGroupProps): React.ReactElement => {
  return (
    <div className="image-carousel-50-50__custom-dots">
      {Array.from({ length: totalSlides }).map((_, i) => {
        const isActive = i === currentIndex;
        return (
          <button
            key={i}
            onClick={() => setCurrentIndex(i)}
            className={`image-carousel-50-50__custom-dot ${isActive ? 'image-carousel-50-50__custom-dot--selected' : ''}`}
          >
          </button>
        );
      })}
    </div>
  );
};

export const ImageCarousel5050 = (props: ImageCarousel5050Props): React.ReactElement => {
  const { tabs, imageAlignment, bgColor } = props
  const [selectedTab, setSelectedTab] = useState(0);

  function nextPage() {
    if (selectedTab === (tabs.length - 1)) {
      setSelectedTab(0)
    } else {
      setSelectedTab(selectedTab + 1)
    }
  }

  function prevPage() {
    if (selectedTab === 0) {
      setSelectedTab(tabs.length - 1)
    } else {
      setSelectedTab(selectedTab - 1)
    }
  }

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className="image-carousel-50-50 container container--large">
        <CarouselProvider
          naturalSlideWidth={2}
          naturalSlideHeight={.5}
          totalSlides={tabs.length}
          currentSlide={selectedTab}
          isIntrinsicHeight={true}
          className="image-carousel-50-50__carousel-container"
        >
          <Slider classNameTray="test">
            {tabs.map(({ id, title, description, image, cta }, index) => {

              return (
                <Slide key={id} index={index} innerClassName={imageAlignment === "left"
                  ? "image-carousel-50-50__content"
                  : "image-carousel-50-50__content--right"
                }>
                  <>
                    <div className='image-carousel-50-50__content-image'>
                      <StrapiImage data={image} />
                    </div>
                    <div className="image-carousel-50-50__content-text">
                      <div className="tabbed-carousel-50-50__top">
                        <div className="image-carousel-50-50__content-btn-left">
                          <Button
                            onClick={prevPage}
                            customClassName={`button--image-carousel-50-50-arrow`}
                            icon={'arrow-left'}
                          />
                        </div>
                        <h2 id="header">{title}</h2>
                        <div className="image-carousel-50-50__content-btn-right">
                          <Button
                            onClick={nextPage}
                            customClassName={`button--image-carousel-50-50-arrow`}
                            icon={'arrow-right'}
                          />
                        </div>
                      </div>
                      <Text text={description} />
                      <div className="image-carousel-50-50__content-button">
                        <StrapiButton
                          data={cta}
                          customClassName={`button--${cta.className}`}
                        />
                      </div>
                      <div className="image-carousel-50-50__content-tabs">
                        <CustomDotGroup
                          setCurrentIndex={setSelectedTab}
                          totalSlides={tabs.length}
                          currentIndex={selectedTab}
                        />
                      </div>
                    </div>
                  </>
                </Slide>
              )
            })}
          </Slider>
        </CarouselProvider>
      </div>
    </Wrapper>
  );
};
