import React, { useState } from 'react';
import { Tab } from 'components/Buttons';
import { ColorDivider } from 'components/Dividers/Dividers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faGripLinesVertical, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
// import { useNavigate } from 'react-router-dom';
import './stylesheets/tabbed-nav.scss';

interface PageLinkData {
  data: {
    attributes: {
      slug: string;
    }
  };
}

interface BaseTab {
  title?: string;
  collection: 'discover' | 'engage' | 'give' | 'receive' | 'other';
  discoverPageLink?: PageLinkData;
  engagePageLink?: PageLinkData;
  givePageLink?: PageLinkData;
  receivePageLink?: PageLinkData;
  otherPageLink?: PageLinkData
}

export interface Tab extends BaseTab {
  subTabs?: BaseTab[];
}

export interface TabbedNavProps {
  tabs: {
    data: {
      attributes: {
        tabs: Tab[];
      };
    };
  }
  activeTab?: string;
}

// in strapi the collection is a dropdown representing which collection we want to pull the page link from
// this function pulls the slug from the correct page link data based on the collection
const getTabSlug = (tab: Tab) => {
  const { collection, discoverPageLink, engagePageLink, givePageLink, receivePageLink, otherPageLink } = tab;

  const collectionToSlugMap: Record<string, string | undefined> = {
    'discover': discoverPageLink?.data?.attributes?.slug,
    'engage': engagePageLink?.data?.attributes?.slug,
    'give': givePageLink?.data?.attributes?.slug,
    'receive': receivePageLink?.data?.attributes?.slug,
    'other': otherPageLink?.data?.attributes?.slug
  };

  return collectionToSlugMap[collection];
}

export const TabbedNav: React.FC<TabbedNavProps> = ({ tabs, activeTab }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeSubTab, setActiveSubTab] = useState<string | null>(null); // State to track active subtab for toggling
  const currentSlug = activeTab || window.location.pathname;

  // Function to check if a tab or its subtab is active
  const isTabOrSubtabActive = (tab: Tab) => {
    return getTabSlug(tab) === currentSlug || tab.subTabs?.some(subTab => getTabSlug(subTab) === currentSlug);
  };

  // Render a single tab item
  const renderTabItem = (tab: Tab, index: number, isSubTab = false) => {
    const tabSlug = getTabSlug(tab);
    const isActive = isSubTab ? tabSlug === currentSlug : isTabOrSubtabActive(tab);
    // Determine the base class based on whether it's a subtab or not
    const baseClass = isSubTab ? 'tabbed-nav__sub-item' : 'tabbed-nav__item';
    // Only add the active class suffix if the tab is active
    const className = isActive ? `${baseClass}--active` : baseClass;

    return (
      <li key={`${tab.title}-${index}`} className={className}>
        <a href={tabSlug}>{tab.title}</a>
      </li>
    );
  };

  // Identify the active main tab or its parent
  const activeMainTabOrParent = tabs.data.attributes.tabs.find(isTabOrSubtabActive);

  // Reorder tabs for mobile view, excluding the active main tab or its parent
  const reorderedTabs = tabs.data.attributes.tabs.filter(tab => tab !== activeMainTabOrParent);

  // Toggle function for subtabs
  const toggleSubTab = (slug: string | undefined) => {
    if (slug) {
      setActiveSubTab(activeSubTab === slug ? null : slug);
    }
  };

  return (
    <div className="tabbed-nav">
      {/* Desktop Content */}
      <div className="tabbed-nav__desktop">
        <div className="container container--less-padding">
          <ul className="tabbed-nav__main">
            {tabs.data.attributes.tabs.map((tab, index) => renderTabItem(tab, index))}
          </ul>
        </div>
        <ColorDivider className="tabbed-nav__desktopDivider" />
        <div className="container">
          {activeMainTabOrParent && activeMainTabOrParent?.subTabs && activeMainTabOrParent?.subTabs[0] && (
            <ul className="tabbed-nav__sub">
              <hr />
              {activeMainTabOrParent.subTabs.map((subTab, index) => renderTabItem(subTab, index, true))}
              <hr />
            </ul>
          )}
        </div>
      </div>

      <div className="tabbed-nav__drawer">
        {/* Mobile Drawer Toggle */}
        <div className="tabbed-nav__drawer--toggle" onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
          <span className="tabbed-nav__drawer--toggleTitle">
            {activeMainTabOrParent &&
              <a onClick={(e) => {
                e.stopPropagation(); // Prevents the link from being triggered
              }}
                href={getTabSlug(activeMainTabOrParent)}>{activeMainTabOrParent?.title}</a>
            }
          </span>
          <span className="tabbed-nav__drawer--toggleIcon">
            {!isDrawerOpen ? <FontAwesomeIcon icon={faGripLines} /> : <FontAwesomeIcon icon={faGripLinesVertical} />}
          </span>
        </div>

        {/* Mobile Drawer Content */}
        {isDrawerOpen && (
          <div className="tabbed-nav__drawer--content">
            <div className="tabbed-nav__drawer--contentTopTab">
              {/* Only display subtabs of the active main tab */}
              {activeMainTabOrParent && activeMainTabOrParent.subTabs && (
                <ul>
                  {activeMainTabOrParent.subTabs.map((subTab, index) => renderTabItem(subTab, index, true))}
                </ul>
              )}
            </div>

            {/* Other Tabs */}
            {reorderedTabs.map((tab, index) => (
              <div key={index} className="tabbed-nav__drawer--contentContainer">
                <div className="tabbed-nav__drawer--contentTab">
                  <a href={getTabSlug(tab)}>{tab.title}</a>
                  {tab.subTabs && tab.subTabs.length > 0 && (
                    <div
                      className="tabbed-nav__drawer--contentTabToggle"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents the link from being triggered
                        toggleSubTab(getTabSlug(tab) || '');
                      }}
                    >
                      {activeSubTab === getTabSlug(tab) && tab.subTabs ?
                        <FontAwesomeIcon icon={faMinus} />
                        :
                        <FontAwesomeIcon icon={faPlus} />
                      }
                    </div>
                  )}
                </div>
                {activeSubTab === getTabSlug(tab) && tab.subTabs && (
                  <ul>
                    {tab.subTabs.map((subTab, subIndex) => renderTabItem(subTab, subIndex, true))}
                  </ul>
                )}
              </div>
            ))}

          </div>
        )}
      </div>
      <ColorDivider className="tabbed-nav__mobileDivider" />
    </div>
  );
}