import React from "react"

import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'shared/interfaces/Link';

import "./stylesheets/copy-blocks.scss"
import { Button } from "components/Buttons";
import { IntroCta, IntroProps } from "./Intro";
import Text from 'components/Text';
import Wrapper from "components/Strapi/Wrapper";
import { mapColor } from "shared/helpers/colorMapping";

import { underscoresToDashes } from "shared/helpers/textHelpers";

type ColumnListContainerProps = {
  header: string
  columnList: ColumnListProps[]
  bgColor?: {
    selectedColor: string
  },
}

type ColumnListProps = {
  header: string
  description?: string
  listItems: string
}

type TwoColumnListProps = {
  twoColumnList: {
    bgColor?: {
      selectedColor: string
    },
    content: {
      text: string;
      icon?: IconName | string;
      link?: Link,
      buttonIcon?: IconName | string;
      iconColor: {
        selectedColor: string;
      }
    }[]
  }[]
}

type ThreeColumnListProps = {
  bgColor?: {
    selectedColor: string
  },
  introCta: IntroProps[];
}

type FourRowIconProps = {
  bgColor?: {
    selectedColor: string;
  },
  fourRowIcon: FourRowIconDataProps[];
}

type FourRowIconDataProps = {
  iconBackground?: {
    selectedColor: string
  },
  iconColor?: {
    selectedColor: string
  }
  descriptionBackground?: {
    selectedColor: string
  },
  dividerBgColor?: {
    selectedColor: string
  },
  icon: IconName | string;
  faHeader: string;
  contentTitle: string;
  contentDescription: string;
  benefitsTitle: string;
  benefitsBulletPoints: string;
}

export const ColumnListContainer = (
  props: ColumnListContainerProps,
): React.ReactElement => {
  const { header, columnList, bgColor } = props
  return (
    <section>
      <div className={`background--${bgColor ? mapColor(bgColor?.selectedColor) : ''}`}>
        <div className={`column-lists column-lists container container--large`}>
          <div className={`column-lists__container column-lists__container`}>
            <h2>{header}</h2>
            {columnList.map((columnList) => {
              return (
                <ColumnList
                  key={columnList.header}
                  header={columnList.header}
                  description={columnList.description}
                  listItems={columnList.listItems}
                />
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

const ColumnList = (props: ColumnListProps): React.ReactElement => {
  const { header, description, listItems } = props

  return (
    <div className='column-list'>
      <div className='column-list column-list__content'>
        <h3>{header}</h3>
        <Text text={description} />
        <div className='column-list column-list__layout'>
          <Text text={listItems} />
        </div>
      </div>
    </div>
  )
}

const getIcon = (icon: [IconPrefix, IconName] | IconName | string | { icon: string, id: string }): [IconPrefix, IconName] | IconName => {
  if (typeof icon === 'string') {
    return underscoresToDashes(icon) as IconName;
  } else if (Array.isArray(icon)) {
    return icon.map(part => underscoresToDashes(part as string)) as [IconPrefix, IconName];
  } else if (typeof icon === 'object' && 'icon' in icon) {
    // Assuming 'icon' field holds the string name of the icon in the object
    return underscoresToDashes(icon.icon) as IconName;
  } else {
    return underscoresToDashes((icon as IconName)) as IconName;
  }
};

export const TwoColumnList = ({ twoColumnList }: TwoColumnListProps): React.ReactElement => {
  return (
    <div>
      {twoColumnList?.map(({ bgColor, content }, index) => {
        return (
          <Wrapper bgColor={bgColor?.selectedColor} key={index} className='two-column-list'>
            <div className="two-column-list__content container container--large">
              {content.map(({ text, link, icon, buttonIcon, iconColor }, buttonIndex) => {
                if (icon && buttonIcon) {
                  const iconData = getIcon(icon)
                  const iconButtonData = getIcon(buttonIcon)
                  return (
                    <Button
                      key={buttonIndex}
                      text={text}
                      link={link}
                      icon={iconData}
                      extraButtonIcon={iconButtonData}
                      customClassName={`button--two-column-icon`}
                      iconColor={`${mapColor(iconColor?.selectedColor)}`}
                    />
                  )
                } else {
                  return <p key={buttonIndex}>No Icon Data</p>
                }
              })}
            </div>
          </Wrapper>
        );
      })}
    </div>
  );
}

export const ThreeColumnList = (props: ThreeColumnListProps): React.ReactElement => {
  const { bgColor, introCta } = props;

  return (
    <Wrapper bgColor={bgColor?.selectedColor} noPadding={true} className='three-column-list'>
      <div className='container container--large'>
        {introCta.map(({ title, desc, ctaText }, index) => (
          <IntroCta
            key={index}
            title={title}
            desc={desc}
            ctaText={ctaText}
          />
        ))}
      </div>
    </Wrapper>
  );
};

export const FourRowIcon = (props: FourRowIconProps): React.ReactElement => {
  const { fourRowIcon, bgColor } = props;

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className="container container--large four-row-icon">
        {fourRowIcon.map(({
          iconBackground,
          iconColor,
          descriptionBackground,
          dividerBgColor,
          icon,
          faHeader,
          contentTitle,
          contentDescription,
          benefitsTitle,
          benefitsBulletPoints
        }, fourRowIndex) => {
          const iconData = getIcon(icon)
          return (
            <div className="four-row-icon__content" key={fourRowIndex}>
              <div className="four-row-icon__content__fa-section">
                <div className={`four-row-icon__content__icon-background background--${iconBackground ? mapColor(iconBackground?.selectedColor) : ''}`}>
                  <FontAwesomeIcon icon={iconData} className={`fa--${iconColor ? mapColor(iconColor?.selectedColor) : ''}`} />
                </div>
                <h3>{faHeader}</h3>
              </div>
              <div className={`four-row-icon__content__description-section background--${descriptionBackground ? mapColor(descriptionBackground?.selectedColor) : ''}`}>
                <hr className={`four-row-icon__content__divider background--${dividerBgColor ? mapColor(dividerBgColor?.selectedColor) : ''}`} />
                <div className="four-row-icon__content__description-section__content">
                  <h4>{contentTitle}</h4>
                  <Text text={contentDescription} />
                </div>
              </div>
              <div className="four-row-icon__content__benefits-section">
                <h4>{benefitsTitle}</h4>
                <Text text={benefitsBulletPoints} />
              </div>
            </div>
          )
        })}
      </div>
    </Wrapper>
  );
};
